import React from 'react';
import { compose, graphql, withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import moment from 'moment-timezone';
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Checkbox,
  Container,
  ControlLabel,
  FlexboxGrid,
  Form,
  FormGroup,
  Loader,
  HelpBlock,
  Icon,
  Input,
  InputGroup,
  InputPicker,
  Message,
  Modal,
  Nav,
  Navbar,
  SelectPicker,
  Sidenav,
  Table,
  TablePagination,
  Toggle,
  Tooltip,
  Whisper,
} from 'rsuite';
import timezones from 'compact-timezone-list';
import { Link } from 'react-router-dom';
import Breadcrumbs from '../../../Components/Breadcrumbs';
import References from '../../../Helpers/References';
import UnitOta from '../../../Components/UnitOta';

import queries from './queries';

import sha1 from 'sha1';

class UserActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmDelete: false,
      working: false,
    }

    this.handleDeleteUser = this.handleDeleteUser.bind(this);
    this.doDeleteUser = this.doDeleteUser.bind(this);
  }

  handleDeleteUser() {
    this.setState({
      showConfirmDelete: true,
    });
  }

  doDeleteUser() {
    this.setState({
      working: true
    });

    const mutation = gql`
    mutation um_deleteUser ($user_id: Int!) {
      um_deleteUser (user_id: $user_id)
    }`;
    const variables = {
      user_id: this.props.rowData.user_id,
    }

    this.props.client.mutate({mutation, variables})
    .then((res) => {
      console.log('delete user success:', res);
      this.props.onDelete();
      this.setState({
        working: false
      });
    })
    .catch((err) => {
      console.log('delete user error:', err);
    })
  }

  render() {
    return (
      !this.state.working
      ? !this.state.showConfirmDelete
        ? <span>
          <a onClick={this.props.handleEdit.bind(this)}><Icon icon="pencil" /></a>&nbsp;
          <a onClick={this.props.handleEmail.bind(this)}><Icon icon="envelope" /></a>&nbsp;
          {
            this.props.account && this.props.account.masteruser_id != this.props.rowData.user_id
            ? <a onClick={this.handleDeleteUser}><Icon icon="trash" /></a>
            : ''
          }
        </span>
        : <ButtonGroup>
            <Button size="xs" color="red" onClick={this.doDeleteUser}><Icon icon="trash" /> Confirm</Button>
            <Button
              size="xs"
              onClick={() => {
                this.setState({showConfirmDelete: false});
              }}
            ><Icon icon="ban" /> Cancel</Button>
          </ButtonGroup>
      : <Loader title="Working..." />
    );
  }
}

class Customer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: props.match.params && props.match.params.tab && ['account', 'units', 'users'].indexOf(props.match.params.tab) >= 0 ? props.match.params.tab : 'account',
      account: {},
      savingAccountDetail: false,
      unitsFilter: '',
      sortUnitsColumn: '',
      sortUnitsType: '',
      editUnitSelectedTab: 'unit',
      showEditUnitModal: false,
      editUnit: {},
      editUnitSaving: false,
      activeUnitsPage: 1,
      unitsDisplayLength: 10,
      showUserDetailModal: false,
      userDetailModelTitle: <React.Fragment><Icon icon="avatar" /> New User</React.Fragment>,
      userDetailActionButtonText: <React.Fragment><Icon icon="save" /> Add</React.Fragment>,
      editUser: null,
      newUser: {},
      // Checkbox stuff
      selectAllUnits: false,
      indeterminate: false,
      selectedUnits: [],
      // Mass Ota
      showMassOtaModal: false,
      // Email User
      showEmailUserModal: false,
      emailUser: null,
    };

    this.getSortedUnits = this.getSortedUnits.bind(this);
    this.renderActiveEditUnitForm = this.renderActiveEditUnitForm.bind(this);
    this.handleSortUnitsColumn = this.handleSortUnitsColumn.bind(this);
    this.handleChangeActiveUnitsPage = this.handleChangeActiveUnitsPage.bind(this);
    this.handleChangeUnitsDisplayLength = this.handleChangeUnitsDisplayLength.bind(this);
    this.handleUserDetailFieldChange = this.handleUserDetailFieldChange.bind(this);
    this.saveAccountDetail = this.saveAccountDetail.bind(this);
    this.saveUserDetail = this.saveUserDetail.bind(this);
    this.saveEditUnit = this.saveEditUnit.bind(this);
    this.handleCheckAllUnits = this.handleCheckAllUnits.bind(this);
    this.handleCheckUnit = this.handleCheckUnit.bind(this);
    this.renderMassOtaModal = this.renderMassOtaModal.bind(this);
    this.renderEmailUserModal = this.renderEmailUserModal.bind(this);
    this.sendUserEmail = this.sendUserEmail.bind(this);
  }

  componentDidMount() {
    console.log('did mount');
    if (this.props.account && this.props.account.um_account) {
      this.mapAccount(this.props.account.um_account);
    }

    // Map edit unit on incoming url    
    if (
      this.props.units &&
      this.props.units.um_units &&
      this.props.match.params.tab &&
      this.props.match.params.tab == 'units' &&
      this.props.match.params.id
    ) {      
      console.log('URL unit id:', this.props.match.params.id);
      this.props.units.um_units.forEach(unit => {
        if (unit.unit_id == this.props.match.params.id) {
          this.setState({
            showEditUnitModal: true,
            editUnit: unit
          });
        }

        if (this.props.match.params.subtab && this.props.match.params.subtab == 'vehicle') {
          this.setState({
            editUnitSelectedTab: 'vehicle'
          });
        }
      });
    }
  }

  mapAccount(account) {
    this.setState({account});
  }

  componentWillReceiveProps(props) {
    console.log('Customer Props:', props);

    if (props.account && props.account.um_account) {
      this.mapAccount(props.account.um_account);
    }

    // Map edit unit on incoming url    
    if (
      props.units &&
      props.units.um_units &&
      props.match.params.tab &&
      props.match.params.tab == 'units' &&
      props.match.params.id
    ) {      
      console.log('URL unit id:', props.match.params.id);
      props.units.um_units.forEach(unit => {
        if (unit.unit_id == props.match.params.id) {
          this.setState({
            showEditUnitModal: true,
            editUnit: unit
          });
        }

        if (props.match.params.subtab && props.match.params.subtab == 'vehicle') {
          this.setState({
            editUnitSelectedTab: 'vehicle'
          });
        }
      });
    }
  }

  getSortedUnits() {
    const { sortUnitsColumn, sortUnitsType } = this.state;
    const start = this.state.unitsFilter.length < 1
      ? (this.state.activeUnitsPage-1) * this.state.unitsDisplayLength
      : 0;
    const end = this.state.unitsFilter.length < 1 && ((start + this.state.unitsDisplayLength) < this.props.units.um_units.length)
      ? start + this.state.unitsDisplayLength
      : this.props.units.um_units.length;
    const filteredUnits = this.state.unitsFilter.length > 0
    ? this.props.units.um_units.filter((unit) => {      
      return (
        unit.label.toLowerCase().includes(this.state.unitsFilter.toLowerCase()) ||
        unit.unittype.name.toLowerCase().includes(this.state.unitsFilter.toLowerCase()) ||
        unit.imei.toLowerCase().includes(this.state.unitsFilter.toLowerCase()) ||
        unit.serial.toLowerCase().includes(this.state.unitsFilter.toLowerCase())
      )
    })
    : this.props.units.um_units;

    if (sortUnitsColumn && sortUnitsType) {
      return filteredUnits.sort((a, b) => {
        let x = a[sortUnitsColumn];
        let y = b[sortUnitsColumn];
        if (typeof x === 'string' && (sortUnitsColumn != 'imei' && sortUnitsColumn != 'serial')) {
          x = x.charCodeAt();
        }
        if (typeof y === 'string' && (sortUnitsColumn != 'imei' && sortUnitsColumn != 'serial')) {
          y = y.charCodeAt();
        }
        if (sortUnitsType === 'asc') {
          return x - y;
        } else {
          return y - x;
        }
      }).slice(start, end);
    }

    return filteredUnits.slice(start, end);
  }

  handleSortUnitsColumn(sortUnitsColumn, sortUnitsType) {
    this.setState({sortUnitsColumn, sortUnitsType});
  }

  handleChangeActiveUnitsPage(e) {
    this.setState({
      activeUnitsPage: e
    });
  }

  handleChangeUnitsDisplayLength(e) {
    this.setState({
      activeUnitsPage: 1,
      unitsDisplayLength: e
    });
  }

  async saveEditUnit() {
    // Save unit info
    let mutation = gql`mutation um_updateUnit(
      $unit_id: Int!,
      $label: String!,
      $unittype_id: Int!,
      $term: Int!,
      $rgeo: Boolean!,
      $imei: String!,
      $serial: String!,
      $simnumber: String,
      $simphone: String,
      $invoice_number: String
    ) {
      um_updateUnit(
        unit_id: $unit_id,
        label: $label,
        unittype_id: $unittype_id,
        term: $term,
        rgeo: $rgeo,
        imei: $imei,
        serial: $serial,
        simnumber: $simnumber,
        simphone: $simphone,
        invoice_number: $invoice_number
      )
    }`;
    let variables = this.state.editUnit;

    this.setState({editUnitSaving: true});

    await this.props.client.mutate({mutation, variables})
    .then((res) => {
      console.log('Save Unit successfull:', res);
    })
    .catch((err) => {
      console.error('Error saving unit:', err);
    });

    // Save vehicle info
    mutation = gql`mutation um_updateVehicle (
      $vehicle_id: Int!,
      $label: String,
      $vehiclemake_id: Int,
      $model: String,
      $year: Int,
      $color: String,
      $vin: String,
      $stocknumber: String,
      $license: String,
      $odometer: Float,
      $notes: String
    ) {
      um_updateVehicle (
        vehicle_id: $vehicle_id,
        label: $label,
        vehiclemake_id: $vehiclemake_id,
        model: $model,
        year: $year,
        color: $color,
        vin: $vin,
        stocknumber: $stocknumber,
        license: $license,
        odometer: $odometer,
        notes: $notes
      )
    }`;
    variables = this.state.editUnit.vehicle;

    await this.props.client.mutate({mutation, variables})
    .then((res) => {
      console.log('Save Vehicle successfull:', res);
    })
    .catch((err) => {
      console.error('Error saving vehicle:', err);
    });

    this.setState({
      editUnitSaving: false,
      showEditUnitModal: false,
      editUnit: {},
    });

    this.props.units.refetch()
    .then((res) => {
      this.props.history.push('/customer/'+this.props.account.um_account.compid+'/units');
    });
  }

  saveAccountDetail() {
    if (this.state.account) {
      console.log('Saving account detail');
      const mutation = gql`mutation um_updateAccount (
        $compid: Int!,
        $title: String!,
        $assman: Boolean!
      ) {
        um_updateAccount (
          compid: $compid,
          title: $title,
          assman: $assman
        )
      }`;
      const variables = this.state.account;

      this.setState({
        savingAccountDetail: true,
      });

      this.props.client.mutate({mutation, variables})
      .then(res => {
        console.log('Update account successfull:', res);
        this.setState({
          savingAccountDetail: false,
        });
        this.props.account.refetch();
      })
      .catch(err => {
        console.error('ERROR updating account:', err);
      })
    }
  }

  saveUserDetail() {
    if (this.state.editUser != null) {
      console.log('edit');
      let mutation = gql`mutation um_updateUser (
        $user_id: Int!,
        $firstname: String!,
        $lastname: String!,
        $username: String!,
        $email: String!,
        $phone: String!,
        $timezone: String!
      ) {
        um_updateUser (
          user_id: $user_id,
          firstname: $firstname,
          lastname: $lastname,
          username: $username,
          email: $email,
          phone: $phone,
          timezone: $timezone
        )
      }`;
      let variables = this.state.editUser;
      if (this.state.editUser.password.length > 0) {
        mutation = gql`mutation um_updateUser (
          $user_id: Int!,
          $firstname: String!,
          $lastname: String!,
          $username: String!,
          $password: String!,
          $email: String!,
          $phone: String!,
          $timezone: String!
        ) {
          um_updateUser (
            user_id: $user_id,
            firstname: $firstname,
            lastname: $lastname,
            username: $username,
            password: $password,
            email: $email,
            phone: $phone,
            timezone: $timezone
          )
        }`;
        variables.password = sha1(this.state.editUser.password);
      }

      console.log('VARS:', variables);

      this.props.client.mutate({mutation, variables})
      .then((res) => {
        this.props.users.refetch();
        this.setState({
          showUserDetailModal: false
        });
      })
      .catch((err) => {
        console.error('Error edit user:', err);
      });
    } else {
      console.log('new');
      const mutation = gql`mutation um_addUser (
        $compid: Int!,
        $firstname: String!,
        $lastname: String!,
        $username: String!,
        $password: String!,
        $email: String!,
        $phone: String!,
        $timezone: String!,
      ) {
        um_addUser (
          compid: $compid,
          firstname: $firstname,
          lastname: $lastname,
          username: $username,
          password: $password,
          email: $email,
          phone: $phone,
          timezone: $timezone          
        )
      }`;
      const variables = {
        ...this.state.newUser,
        compid: this.props.match.params.compid,
        password: sha1(this.state.newUser.password),
      };

      console.log('VARS:', variables);

      this.props.client.mutate({mutation, variables})
      .then((res) => {
        this.props.users.refetch();
        this.setState({
          showUserDetailModal: false
        });
      })
      .catch((err) => {
        console.error('Error creating new user:', err);
      });
    }
  }

  renderActiveTab() {
    switch (this.state.activeTab) {
      case 'account': return this.renderAccountTab();
      case 'units': return this.renderUnitsTab();
      case 'users': return this.renderUsersTab();
      default: return this.renderUnknownTab();
    }
  }

  renderAccountTab() {
    return <Container>
      {
        this.state.account && this.state.account.title
        ? <Form fluid>
          <FormGroup>
            <ControlLabel>Company Name</ControlLabel>
            <Input value={this.state.account.title} onChange={title => this.setState({account: {...this.state.account, title}})}/>
          </FormGroup>
          <FormGroup>
            <ControlLabel>Asset Manager Access</ControlLabel>
            <Toggle
              checkedChildren="Enabled"
              unCheckedChildren="Disabled"
              checked={this.state.account.assman}
              onChange={assman => this.setState({account: {...this.state.account, assman}})}
            />
          </FormGroup>
          <Button
            color="green"
            onClick={this.saveAccountDetail}
            disabled={this.state.savingAccountDetail === true}
          >
          {
            this.state.savingAccountDetail === true
            ? <Loader content="Saving..." />
            : <React.Fragment><Icon icon="save" /> Save</React.Fragment>
          }</Button>
        </Form>
        : <Loader content="Loading..." />
      }      
    </Container>
  }

  handleCheckAllUnits(value, checked) {
    const selectedUnits = checked ? this.props.units.um_units.map(unit => unit.unit_id) : [];
    this.setState({
      selectAllUnits: value,
      selectedUnits
    });
  }

  handleCheckUnit(value, checked) {
    const { selectedUnits } = this.state;
    const nextSelectedUnits = checked
    ? [...selectedUnits, value]
    : selectedUnits.filter(unit_id => unit_id !== value);

    console.log('nextSelectedUnits:', nextSelectedUnits);

    this.setState({
      selectedUnits: nextSelectedUnits,
      selectAllUnits: nextSelectedUnits.length > 0 && nextSelectedUnits.length == this.props.units.um_units.length
      ? true
      : false
    });
  }

  renderMassOtaModal() {
    return <Modal show={this.state.showMassOtaModal} keyboard onExit={() => {this.setState({showMassOtaModal: false})}}>
      <Modal.Header closeButton={false}><Modal.Title><Icon icon="send" /> Mass OTA</Modal.Title></Modal.Header>
      <Modal.Body>
        <Form>
          <FormGroup>
            <Table
              data={this.props.units && this.props.units.um_units
                ? this.props.units.um_units.filter(unit => this.state.selectedUnits.includes(unit.unit_id))
                : []
              }
            >
              <Table.Column>
                <Table.HeaderCell>ID</Table.HeaderCell>
                <Table.Cell dataKey="unit_id" />
              </Table.Column>
              <Table.Column>
                <Table.HeaderCell>Label</Table.HeaderCell>
                <Table.Cell dataKey="label" />
              </Table.Column>
              <Table.Column>
                <Table.HeaderCell>type</Table.HeaderCell>
                <Table.Cell dataKey="unittype.name" />
              </Table.Column>
              <Table.Column>
                <Table.HeaderCell>IMEI</Table.HeaderCell>
                <Table.Cell dataKey="imei" />
              </Table.Column>
              <Table.Column>
                <Table.HeaderCell>Serial</Table.HeaderCell>
                <Table.Cell dataKey="serial" />
              </Table.Column>
            </Table>
          </FormGroup>
          <UnitOta
            units={this.props.units && this.props.units.um_units
              ? this.props.units.um_units.filter(unit => this.state.selectedUnits.includes(unit.unit_id))
              : []
            }
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => {this.setState({showMassOtaModal: false})}}><Icon icon="check" /> Done</Button>
      </Modal.Footer>
    </Modal>
  }

  renderUnitsTab() {
    return <Container>
      {
      this.props.units && this.props.units.um_units
      ? <React.Fragment>
        <Input placeholder="Filter" value={this.state.unitsFilter} onChange={unitsFilter => {this.setState({unitsFilter})}} />
        <Table
          autoHeight
          data={this.getSortedUnits()}
          sortColumn={this.state.sortUnitsColumn}
          sortType={this.state.sortUnitsType}
          onSortColumn={this.handleSortUnitsColumn}
        >
          <Table.Column fixed width={50} align="center">
            <Table.HeaderCell style={{padding: 2}}>
              <div style={{ lineHeight: 50 }}>
                <Checkbox
                  onChange={this.handleCheckAllUnits}
                  indeterminate={this.state.selectedUnits.length > 0 && this.state.selectedUnits.length < this.props.units.um_units.length}
                  checked={this.state.selectAllUnits}
                />
              </div>
            </Table.HeaderCell>
            <Table.Cell style={{padding: 2}}>
              {
                rowData => {
                  return <div style={{lineHeight: 46}}>
                    <Checkbox
                      onChange={this.handleCheckUnit}
                      checked={this.state.selectedUnits.some(unit_id => unit_id === rowData.unit_id)}
                      value={rowData.unit_id}
                    />
                  </div>
                }
              }
            </Table.Cell>
          </Table.Column>
          <Table.Column sortable align="center" fixed width={50}>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.Cell dataKey="unit_id" />
          </Table.Column>
          <Table.Column sortable fixed resizable width={200}>
            <Table.HeaderCell>Label</Table.HeaderCell>
            <Table.Cell>
            {
              rowData => {
                return <Link to={'/customer/'+rowData.compid+'/units/'+rowData.unit_id}>{rowData.label}</Link>
              }
            }
            </Table.Cell>
          </Table.Column>
          <Table.Column width={200}>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.Cell dataKey="unittype.name" />
          </Table.Column>
          <Table.Column width={200} sortable>
            <Table.HeaderCell>IMEI</Table.HeaderCell>
            <Table.Cell dataKey="imei" />
          </Table.Column>
          <Table.Column width={200} sortable>
            <Table.HeaderCell>Serial</Table.HeaderCell>
            <Table.Cell dataKey="serial" />
          </Table.Column>
          <Table.Column width={200} sortable>
            <Table.HeaderCell>Sim</Table.HeaderCell>
            <Table.Cell dataKey="simnumber" />
          </Table.Column>
          <Table.Column width={100} sortable>
            <Table.HeaderCell>Created</Table.HeaderCell>
            <Table.Cell>
            {
              rowData => {
                return moment(rowData.createdAt).format('YYYY/MM/DD')
              }
            }
            </Table.Cell>
          </Table.Column>
          <Table.Column width={100} sortable>
            <Table.HeaderCell>Transfered</Table.HeaderCell>
            <Table.Cell>
            {
              rowData => {
                return rowData.transferdate ? moment.unix(rowData.transferdate).format('YYYY/MM/DD') : 'N/A'
              }
            }
            </Table.Cell>
          </Table.Column>
          <Table.Column width={100} sortable>
            <Table.HeaderCell>Installed</Table.HeaderCell>
            <Table.Cell>
            {
              rowData => {
                return rowData.installationdate ? moment.unix(rowData.installationdate).format('YYYY/MM/DD') : 'Not Installed'
              }
            }
            </Table.Cell>
          </Table.Column>
          <Table.Column width={100} sortable>
            <Table.HeaderCell>Expires</Table.HeaderCell>
            <Table.Cell>
            {
              rowData => {
                return rowData.next_renew_date ? moment.unix(rowData.next_renew_date).format('YYYY/MM/DD') : 'N/A'
              }
            }
            </Table.Cell>
          </Table.Column>
          <Table.Column fixed="right">
            <Table.HeaderCell><Icon icon="cogs" /></Table.HeaderCell>
            <Table.Cell>
            {
              rowData => {
                function handleAction() {
                  this.setState({
                    showEditUnitModal: true,
                    editUnit: rowData,
                  });
                }
                return (
                  <span>
                    <a onClick={handleAction.bind(this)}><Icon icon="pencil" /></a>
                  </span>
                );
              }
            }
            </Table.Cell>
          </Table.Column>
        </Table>
        {
          this.state.unitsFilter.length < 1
          ? <TablePagination
            lengthMenu={[
              {
                value: 10,
                label: 10,
              },
              {
                value: 25,
                label: 25,
              },
              {
                value: 50,
                label: 50,
              },
              {
                value: 100,
                label: 100,
              },
            ]}
            activePage={this.state.activeUnitsPage}
            displayLength={this.state.unitsDisplayLength}
            total={this.props.units && this.props.units.um_units ? this.props.units.um_units.length : 0}
            onChangePage={this.handleChangeActiveUnitsPage}
            onChangeLength={this.handleChangeUnitsDisplayLength}
          />
          : ''
        }
        <ButtonToolbar>
          <ButtonGroup>
            <Button
              appearance="primary"
              onClick={() => {this.setState({showMassOtaModal: true})}}
              disabled={this.state.selectedUnits.length == 0}
            ><Icon icon="send" /> Mass OTA</Button>
          </ButtonGroup>
        </ButtonToolbar>
      </React.Fragment>
      : <Loader content="Loading..." />
      }
      {this.renderEditUnitModal()}
      {this.renderMassOtaModal()}
    </Container>
  }

  renderActiveEditUnitForm() {
    switch (this.state.editUnitSelectedTab) {
      case 'vehicle':
        return (
          <Form fluid>
            <FormGroup>
              <ControlLabel>Label</ControlLabel>
              <Input
                name="label"
                placeholder="label"
                value={this.state.editUnit.vehicle && this.state.editUnit.vehicle.label ? this.state.editUnit.vehicle.label : ''}
                onChange={(e) => {
                  this.setState({
                    editUnit: {
                      ...this.state.editUnit,
                      vehicle: {
                        ...this.state.editUnit.vehicle,
                        label: e
                      }
                    }
                  });
                }}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Make</ControlLabel>
              <SelectPicker
                cleanable={false}
                data={
                  this.props.vehiclemakes && this.props.vehiclemakes.um_vehiclemakes
                  ? this.props.vehiclemakes.um_vehiclemakes.map((vm) => ({label: vm.name, value: vm.vehiclemake_id}))
                  : []
                }
                value={this.state.editUnit.vehicle && this.state.editUnit.vehicle.vehiclemake_id ? this.state.editUnit.vehicle.vehiclemake_id : 0}
                onChange={(e) => {
                  this.setState({
                    editUnit: {
                      ...this.state.editUnit,
                      vehicle: {
                        ...this.state.editUnit.vehicle,
                        vehiclemake_id: e,
                      },
                    }
                  });
                }}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Model</ControlLabel>
              <Input
                name="model"
                placeholder="model"
                value={this.state.editUnit.vehicle && this.state.editUnit.vehicle.model ? this.state.editUnit.vehicle.model : ''}
                onChange={(e) => {
                  this.setState({
                    editUnit: {
                      ...this.state.editUnit,
                      vehicle: {
                        ...this.state.editUnit.vehicle,
                        model: e
                      }
                    }
                  });
                }}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Year</ControlLabel>
              <Input
                name="year"
                placeholder="year"
                value={this.state.editUnit.vehicle && this.state.editUnit.vehicle.year ? this.state.editUnit.vehicle.year : ''}
                onChange={(e) => {
                  this.setState({
                    editUnit: {
                      ...this.state.editUnit,
                      vehicle: {
                        ...this.state.editUnit.vehicle,
                        year: e
                      }
                    }
                  });
                }}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Color</ControlLabel>
              <Input
                name="color"
                placeholder="color"
                value={this.state.editUnit.vehicle && this.state.editUnit.vehicle.color ? this.state.editUnit.vehicle.color : ''}
                onChange={(e) => {
                  this.setState({
                    editUnit: {
                      ...this.state.editUnit,
                      vehicle: {
                        ...this.state.editUnit.vehicle,
                        color: e
                      }
                    }
                  });
                }}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>VIN</ControlLabel>
              <Input
                name="vin"
                placeholder="vin"
                value={this.state.editUnit.vehicle && this.state.editUnit.vehicle.vin ? this.state.editUnit.vehicle.vin : ''}
                onChange={(e) => {
                  this.setState({
                    editUnit: {
                      ...this.state.editUnit,
                      vehicle: {
                        ...this.state.editUnit.vehicle,
                        vin: e
                      }
                    }
                  });
                }}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Stock #</ControlLabel>
              <Input
                name="stocknumber"
                placeholder="stocknumber"
                value={this.state.editUnit.vehicle && this.state.editUnit.vehicle.stocknumber ? this.state.editUnit.vehicle.stocknumber : ''}
                onChange={(e) => {
                  this.setState({
                    editUnit: {
                      ...this.state.editUnit,
                      vehicle: {
                        ...this.state.editUnit.vehicle,
                        stocknumber: e
                      }
                    }
                  });
                }}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>License Plate</ControlLabel>
              <Input
                name="license"
                placeholder="license"
                value={this.state.editUnit.vehicle && this.state.editUnit.vehicle.license ? this.state.editUnit.vehicle.license : ''}
                onChange={(e) => {
                  this.setState({
                    editUnit: {
                      ...this.state.editUnit,
                      vehicle: {
                        ...this.state.editUnit.vehicle,
                        license: e
                      }
                    }
                  });
                }}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Odometer</ControlLabel>
              <Input
                name="odometer"
                placeholder="odometer"
                value={this.state.editUnit.vehicle && this.state.editUnit.vehicle.odometer ? this.state.editUnit.vehicle.odometer : ''}
                onChange={(e) => {
                  this.setState({
                    editUnit: {
                      ...this.state.editUnit,
                      vehicle: {
                        ...this.state.editUnit.vehicle,
                        odometer: e
                      }
                    }
                  });
                }}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Notes</ControlLabel>
              <Input
                name="notes"
                placeholder="notes"
                componentClass="textarea"
                rows={5}
                value={this.state.editUnit.vehicle && this.state.editUnit.vehicle.notes ? this.state.editUnit.vehicle.notes : ''}
                onChange={(e) => {
                  this.setState({
                    editUnit: {
                      ...this.state.editUnit,
                      vehicle: {
                        ...this.state.editUnit.vehicle,
                        notes: e
                      }
                    }
                  });
                }}
              />
            </FormGroup>
          </Form>
        );
      default:
        return (
        <Form fluid>
          <FormGroup>
            <ControlLabel>Label</ControlLabel>
            <Input
              name="label"
              placeholder="label"
              value={this.state.editUnit.label ? this.state.editUnit.label : ''}
              onChange={(e) => {
                this.setState({
                  editUnit: {
                    ...this.state.editUnit,
                    label: e,
                  }
                });
              }}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Unit Type</ControlLabel>
            <SelectPicker
              cleanable={false}
              data={
                this.props.unittypes && this.props.unittypes.um_unitTypes
                ? this.props.unittypes.um_unitTypes.map((ut) => ({label: ut.name, value: ut.unittype_id}))
                : []
              }
              value={this.state.editUnit.unittype_id ? this.state.editUnit.unittype_id : 0}
              onChange={(e) => {
                this.setState({
                  editUnit: {
                    ...this.state.editUnit,
                    unittype_id: e
                  }
                });
              }}
            />
          </FormGroup>
          <FormGroup>
          <ControlLabel>Term</ControlLabel>
          <SelectPicker
            cleanable={false}
            searchable={false}
            data={References.terms}
            value={this.state.editUnit.term}
            onChange={e => {
              this.setState({
                editUnit: {
                  ...this.state.editUnit,
                  term: e
                }
              })
            }}
          />
        </FormGroup>
          <FormGroup>
            <ControlLabel>RGeo</ControlLabel>
            <Toggle
              checked={this.state.editUnit.rgeo ? true : false}
              onChange={e => {
                this.setState({
                  editUnit: {
                    ...this.state.editUnit,
                    rgeo: e
                  }
                })
              }}
              checkedChildren="On"
              unCheckedChildren="Off"
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>IMEI</ControlLabel>
            <Input
              name="imei"
              placeholder="imei"
              value={this.state.editUnit.imei ? this.state.editUnit.imei : ''}
              onChange={(e) => {
                this.setState({
                  editUnit: {
                    ...this.state.editUnit,
                    imei: e,
                  }
                });
              }}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Serial</ControlLabel>
            <Input
              name="serial"
              placeholder="serial"
              value={this.state.editUnit.serial ? this.state.editUnit.serial : ''}
              onChange={(e) => {
                this.setState({
                  editUnit: {
                    ...this.state.editUnit,
                    serial: e,
                  }
                });
              }}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Sim Number</ControlLabel>
            <Input
              name="simnumber"
              placeholder="simnumber"
              value={this.state.editUnit.simnumber ? this.state.editUnit.simnumber : ''}
              onChange={simnumber => {
                this.setState({
                  editUnit: {
                    ...this.state.editUnit,
                    simnumber,
                  }
                });
              }}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Sim Phone</ControlLabel>
            <Input
              name="simphone"
              placeholder="simphone"
              value={this.state.editUnit.simphone ? this.state.editUnit.simphone : ''}
              onChange={simphone => {
                this.setState({
                  editUnit: {
                    ...this.state.editUnit,
                    simphone,
                  }
                });
              }}
            />
          </FormGroup>
          <UnitOta units={[this.state.editUnit]} />
          <FormGroup>
            <ControlLabel>Invoice #</ControlLabel>
            <Input
              name="invoice_number"
              placeholder="Invoice #"
              value={this.state.editUnit.invoice_number ? this.state.editUnit.invoice_number : ''}
              onChange={invoice_number => {
                this.setState({
                  editUnit: {
                    ...this.state.editUnit,
                    invoice_number,
                  }
                });
              }}
            />
          </FormGroup>
        </Form>
      )
    }
  }

  handleUserDetailFieldChange(field, value) {
    if (this.state.editUser != null) {
      let editUser = Object.assign({}, this.state.editUser);
      editUser[field] = value;
      this.setState({editUser});
    } else {
      let newUser = Object.assign({}, this.state.newUser);
      newUser[field] = value;
      this.setState({newUser});
    }
  }

  renderUserDetailsModal() {
    return <Modal keyboard show={this.state.showUserDetailModal}>
      <Modal.Header closeButton={false}><Modal.Title>{this.state.userDetailModelTitle}</Modal.Title></Modal.Header>
      <Modal.Body>
        <Form fluid>
          <FormGroup>
            <ControlLabel>First Name</ControlLabel>
            <Input placeholder="first name" value={this.state.editUser !== null ? this.state.editUser.firstname : this.state.newUser.firstname} onChange={(e) => {this.handleUserDetailFieldChange('firstname', e)}} />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Last Name</ControlLabel>
            <Input placeholder="last name" value={this.state.editUser !== null ? this.state.editUser.lastname : this.state.newUser.lastname} onChange={(e) => {this.handleUserDetailFieldChange('lastname', e)}} />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Username</ControlLabel>
            <Input placeholder="username" value={this.state.editUser !== null ? this.state.editUser.username : this.state.newUser.username} onChange={(e) => {this.handleUserDetailFieldChange('username', e)}} />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Password</ControlLabel>
            <InputGroup>
              <InputGroup.Addon><Icon icon="key" /></InputGroup.Addon>
              <Input type="password" name="password" placeholder="password" value={this.state.editUser !== null ? this.state.editUser.password : this.state.newUser.password} onChange={(e) => {this.handleUserDetailFieldChange('password', e)}} />
            </InputGroup>
            <HelpBlock>* Leave blank unless changing the password</HelpBlock>
          </FormGroup>
          <FormGroup>
            <ControlLabel>Email</ControlLabel>
            <Input placeholder="email" value={this.state.editUser !== null ? this.state.editUser.email : this.state.newUser.email} onChange={(e) => {this.handleUserDetailFieldChange('email', e)}} />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Phone</ControlLabel>
            <Input placeholder="phone" value={this.state.editUser !== null ? this.state.editUser.phone : this.state.newUser.phone} onChange={(e) => {this.handleUserDetailFieldChange('phone', e)}} />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Timezone</ControlLabel>
            <InputPicker cleanable={false} data={timezones.map(tz=>({value: tz.tzCode, label: tz.tzCode}))} value={this.state.editUser !== null ? this.state.editUser.timezone : this.state.newUser.timezone} onChange={e => {this.handleUserDetailFieldChange('timezone', e)}} />
          </FormGroup>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button appearance="primary" color="green" onClick={() => {this.saveUserDetail()}} disabled={this.state.userDetailButtonsDisabled}>{this.state.userDetailActionButtonText}</Button>
        <Button onClick={() => {this.setState({showUserDetailModal: false})}}><Icon icon="ban" /> Cancel</Button>
      </Modal.Footer>
    </Modal>
  }

  renderUsersTab() {
    return <Container>
      <ButtonToolbar>
        <ButtonGroup>
          <Button
            appearance="primary"
            onClick={() => {
              this.setState({
                showUserDetailModal: true,
                userDetailModelTitle: <React.Fragment><Icon icon="avatar" /> New User</React.Fragment>,
                userDetailActionButtonText: <React.Fragment><Icon icon="save" /> Add</React.Fragment>,
                editUser: null,
                newUser: {
                  timezone: moment.tz.guess()
                },
              });
            }}
          ><Icon icon="plus" /> New</Button>
        </ButtonGroup>
      </ButtonToolbar>
      {
        this.props.users && this.props.users.um_users
        ? <Table
          autoHeight
          data={this.props.users.um_users}
        >          
          <Table.Column width={200}>
            <Table.HeaderCell>First Name</Table.HeaderCell>
            <Table.Cell dataKey="firstname" />
          </Table.Column>
          <Table.Column width={200}>
            <Table.HeaderCell>Last Name</Table.HeaderCell>
            <Table.Cell dataKey="lastname" />
          </Table.Column>
          <Table.Column width={200}>
            <Table.HeaderCell>Username</Table.HeaderCell>
            <Table.Cell dataKey="username" />
          </Table.Column>
          <Table.Column width={200}>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.Cell dataKey="email" />
          </Table.Column>
          <Table.Column width={200}>
            <Table.HeaderCell>Phone</Table.HeaderCell>
            <Table.Cell dataKey="phone" />
          </Table.Column>
          <Table.Column width={200}>
            <Table.HeaderCell>Timezone</Table.HeaderCell>
            <Table.Cell dataKey="timezone" />
          </Table.Column>
          <Table.Column width={200}>
            <Table.HeaderCell><Icon icon="cogs" /></Table.HeaderCell>
            <Table.Cell>                
              {
                rowData => {
                  function handleEdit() {
                    this.setState({
                      showUserDetailModal: true,
                      userDetailModelTitle: <React.Fragment><Icon icon="avatar" /> {rowData.firstname} {rowData.lastname} {rowData.user_id == this.props.account.um_account.masteruser_id ? <Whisper placement="top" trigger="hover" speaker={<Tooltip>Account Main User</Tooltip>}><Icon icon="star" /></Whisper> : ''}</React.Fragment>,
                      userDetailActionButtonText: <React.Fragment><Icon icon="save" /> Save</React.Fragment>,
                      editUser: {
                        ...rowData,
                        password: '',
                      },
                    });
                  }

                  function handleEmail() {
                    this.setState({
                      showEmailUserModal: true,
                      emailUser: rowData,
                    });
                  }

                  return <UserActions
                    rowData={rowData}
                    handleEdit={handleEdit.bind(this)}
                    handleEmail={handleEmail.bind(this)}
                    client={this.props.client}
                    onDelete={() => {this.props.users.refetch()}}
                    account={this.props.account.um_account}
                  />;
                }
              }
            </Table.Cell>
          </Table.Column>
        </Table>
        : <Loader content="Loading..." />
      }
      {this.renderUserDetailsModal()}
      {this.renderEmailUserModal()}
    </Container>
  }

  renderUnknownTab() {
    return <Container>Error: Please contact the admin.</Container>
  }

  renderEditUnitModal() {
    return (
      <Modal
        show={this.state.showEditUnitModal}
        keyboard
      >
        <Modal.Header closeButton={false}>
          <Modal.Title>
            <Nav appearance="tabs">
              <Nav.Item
                icon={<Icon icon="location-arrow" />}
                active={this.state.editUnitSelectedTab == 'unit'}
                onClick={() => {this.setState({editUnitSelectedTab:'unit'})}}
              >Unit</Nav.Item>
              <Nav.Item
                icon={<Icon icon="car" />}
                active={this.state.editUnitSelectedTab == 'vehicle'}
                onClick={() => {this.setState({editUnitSelectedTab:'vehicle'})}}
              >Vehicle</Nav.Item>
            </Nav>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            this.renderActiveEditUnitForm()
          }
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={this.saveEditUnit}
            appearance="primary"
            color="green"
          >
          {
            this.state.editUnitSaving
            ? <Loader content="Saving..." />
            : <React.Fragment><Icon icon="save" /> Save</React.Fragment>
          }
          </Button>
          <Button onClick={()=>{this.setState({showEditUnitModal: false})}} appearance="subtle"><Icon icon="ban" /> Cancel</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  sendUserEmail() {
    this.setState({
      sendingUserEmail: true
    });

    const mutation = gql`mutation um_resetUserPasswordWithEmail($user_id: Int!) {
      um_resetUserPasswordWithEmail(user_id: $user_id)
    }`;
    const variables = {
      user_id: this.state.emailUser.user_id,
    };
    this.props.client.mutate({mutation, variables})
    .then((res) => {
      console.log('RES:', res);
      this.setState({
        sendingUserEmail: false,
        showEmailUserModal: false,
        emailUser: null,
      });
    })
    .catch((err) => {
      console.log('ERROR:', err);
    });
  }

  renderEmailUserModal() {
    return <Modal show={this.state.showEmailUserModal}>
      <Modal.Header closeButton={false}>
        <Modal.Title>Email User</Modal.Title>
        <Modal.Body>
          <Message showIcon type="warning" title="Warning!" description="This will reset the user's password." />
        </Modal.Body>
        <Modal.Footer>
          <Button appearance="primary" color="green" onClick={this.sendUserEmail} disabled={this.state.sendingUserEmail}><Icon icon="send" /> Send</Button>
          <Button appearance="subtle" color="red" onClick={() => {this.setState({showEmailUserModal: false, emailUser: null})}}><Icon icon="ban" /> Cancel</Button>
        </Modal.Footer>
      </Modal.Header>
    </Modal>
  }

  render() {
    return <Container>
      <Breadcrumbs
        pages={[{
          url: '/customers',
          name: 'Customers',
        }, {
          url: '/customer/'+this.props.match.params.compid,
          name: this.props.account && this.props.account.um_account ? this.props.account.um_account.title : '...'
        }]}
      />
      <Container className="content">
        <Container className="content-body">          
          <Nav
            appearance="tabs"
            onSelect={(e) => {this.setState({activeTab: e})}}
          >
            <Nav.Item
              icon={<Icon icon="list" />}
              active={this.state.activeTab == 'account'}
              eventKey="account"
            >Account</Nav.Item>
            <Nav.Item
              icon={<Icon icon="location-arrow" />}
              active={this.state.activeTab == 'units'}
              eventKey="units"
            >Units{this.props.account && this.props.account.um_account ? ' (' + this.props.account.um_account.installedUnitCount + '/' + this.props.account.um_account.unitCount + ')' : ''}</Nav.Item>
            <Nav.Item
              icon={<Icon icon="people-group" />}
              active={this.state.activeTab == 'users'}
              eventKey="users"
            >Users{this.props.users && this.props.users.um_users ? ' (' + this.props.users.um_users.length + ')' : ''}</Nav.Item>
          </Nav>
          <Container style={{padding: '5px'}}>
            {this.renderActiveTab()}
          </Container>
        </Container>
      </Container>
    </Container>
  }
}

export default compose(
  graphql(queries.account, {
    name: 'account',
    options: (props) => ({
      variables: {
        compid: props.match.params.compid
      }
    })
  }),
  graphql(queries.units, {
    name: 'units',
    options: (props) => ({
      variables: {
        compid: props.match.params.compid
      }
    })
  }),
  graphql(queries.unittypes, {
      name: 'unittypes'
    }
  ),
  graphql(queries.vehiclemakes, {
      name: 'vehiclemakes'
    }
  ),
  graphql(queries.users, {
    name: 'users',
    options: (props) => ({
      variables: props.match.params
    })
  }),
)(withApollo(Customer));