import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
//import createBrowserHistory from 'history/createBrowserHistory';
import { createBrowserHistory } from 'history';

// Apollo imports
import { ApolloClient } from 'apollo-client';
import { ApolloProvider } from 'react-apollo';
import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';

const history = createBrowserHistory();
const httpLink = createHttpLink({
  uri: 'https://w0ansq5va5.execute-api.us-west-1.amazonaws.com/Prod/graphql', // Prod
  opts: {
    mode: 'no-cors',
  }
});
const authLink = setContext((_, { headers }) => {
  if (localStorage.getItem('GPSLeadersAssetManagerAuthToken') === null) {
    localStorage.setItem('GPSLeadersAssetManagerAuthToken', '');
  }
  const token = localStorage.getItem('GPSLeadersAssetManagerAuthToken');
  return {
    headers: {
      Authorization: token,
      ...headers,
    },
  };
});
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only'
    }
  }
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <BrowserRouter history={history}>
      <App history={history} client={client} />
    </BrowserRouter>
  </ApolloProvider>
  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
