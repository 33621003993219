import React from 'react';
import { Link } from 'react-router-dom';
// React Suite
import {
  Button,
  Container,
  ControlLabel,
  Drawer,
  Dropdown,
  FlexboxGrid,
  Form,
  FormGroup,
  Loader,
  Icon,
  Input,
  InputGroup,
  Modal,
  Nav,
  Navbar,
  Panel,
  Popover,
  Table,
  Timeline,
  Whisper,
} from 'rsuite';

import { compose, graphql, withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import sha1 from 'sha1';
import moment from 'moment-timezone';
import {debounce} from 'lodash';

const USER = gql`
query {
  umUserInfo {
    umuser_id
    compid
    account {
      compid
      title
    }
    username
    password
    title
    logo
    dealer_id
    email_server
    google_map_key
    business_name
    domain_name
    alternate_domain
    login_page_path
    email_domain
    active
  }
}`;

const LOG = gql`
query {
  umLogs (limit: 25) {
    log_id
    action
    type
    details
    umuser_id
    umuser {
      username
      title
    }
    createdAt
  }
}`;

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showProfileModal: false,
      showCompanyModal: false,
      showLogModal: false,
      showSearchModal: false,
      searchString: '',
      searchingUnits: false,
      searchUnits: null,
      searchingAccounts: false,
      searchAccounts: null,
      searchingVehicles: false,
      searchVehicles: null,
      searchingInventory: false,
      searchInventory: null,
      profile: {},
      saveProfileButtonText: 'Save',
      saveProfileButtonDisabled: false,
      saveCompanyButtonText: 'Save',
      saveCompanyButtonDisabled: false,
    };

    this.navigateTo = this.navigateTo.bind(this);
    this.saveProfile = this.saveProfile.bind(this);
    this.doLogout = this.doLogout.bind(this);
    this.renderLogDrawer = this.renderLogDrawer.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  componentDidMount() {}

  componentWillReceiveProps(props) {
    console.log('Header Props:', props);
    if (this.props.disabled && !props.disabled) {
      this.props.user.refetch();
    }

    if (props.user && props.user.umUserInfo) {
      console.log('Mapping umUserInfo');
      this.mapProfileInfo(props.user.umUserInfo);
    }
  }

  mapProfileInfo(profile) {
    this.setState({
      profile: {
        ...profile,
        password: '',
      }
    });
  }

  navigateTo(path) {
    if (path !== window.location.pathname) {
      this.props.history.push(path);
    }
  }

  saveProfile() {
    let mutation = gql`
    mutation updateUmUser ($umuser_id: Int!, $username: String!, $title: String!) {
      updateUmUser (umuser_id: $umuser_id, username: $username, title: $title)
    }`;
    let variables = this.state.profile;

    this.setState({
      saveProfileButtonDisabled: true,
      saveProfileButtonText: 'Saving...',
    });

    if (this.state.profile.password && this.state.profile.password.length > 0) {
      mutation = gql`
      mutation updateUmUser ($umuser_id: Int!, $username: String!, $password: String!, $title: String!) {
        updateUmUser (umuser_id: $umuser_id, username: $username, password: $password, title: $title)
      }`;
      variables.password = sha1(this.state.profile.password);
    }

    this.props.client.mutate({ mutation, variables })
    .then((res) => {
      console.log('[SUCCESS][saveProfile]:', res);
      this.setState({
        showProfileModal: false,
        saveProfileButtonDisabled: false,
        saveProfileButtonText: 'Save',
      });
      this.props.user.refetch();
    })
    .catch((error) => {
      console.error('[ERROR][saveProfile]:', error);
    })
  }

  saveCompany() {
    const mutation = gql`
    mutation um_updateAccount($compid: Int!, $title: String!) {
      um_updateAccount(compid: $compid, title: $title)
    }`;
    const variables = this.state.profile.account;

    this.setState({
      saveCompanyButtonDisabled: true,
      saveCompanyButtonText: 'Saving...'
    });

    this.props.client.mutate({mutation, variables})
    .then((res) => {
      console.log('saveCompany success:', res);
      this.setState({
        saveCompanyButtonDisabled: false,
        saveCompanyButtonText: 'Save',
        showCompanyModal: false,
      });
      this.props.user.refetch();
    })
    .catch((err) => {
      console.error('saveCompany error:', err);
    })
  }

  doLogout() {
    localStorage.removeItem('GPSLeadersAssetManagerAuthToken');
    localStorage.removeItem('GPSLeadersAssetManagerReports');

    // Redirect to base if needed
    if (window.location.pathname !== '/') {
      this.props.history.push('/');
    }

    this.props.onLogout();
  }

  doSearch = debounce(async () => {
      if (this.state.searchString.length > 0) {
      console.log('Doing search:', this.state.searchString);

      // Search for accounts
      let query = gql`query um_accounts ($title: String!) {
        um_accounts (title: $title){
          compid
          title
          pings
          account_type
          dealer
          dealer_type
          account_tz
          email_settings
          maintenance_email
          account_permissions
          account_settings
          state_id
          eot_version
          eot_username
          eot_password
          eot_count
          creation_date
          markup
          security_code
          registered
          opt_out_code
          popups
          paypal_id
          batt_threshold
          root_compid
          defaultbucket_id
          masteruser_id
          installedUnitCount
          unitCount
          assman
        }
      }`;
      let variables = {
        title: this.state.searchString
      };
      this.setState({searchingAccounts: true});
      console.log('Accounts search vars:', variables);
      this.props.client.query({query, variables})
      .then((res) => {
        console.log('Account search results:', res);
        this.setState({searchingAccounts: false, searchAccounts: res.data.um_accounts})
      })
      .catch((err) => {
        console.error('Error with accounts search:', err);
      });

      // Search for units
      query = gql`query um_searchUnits($searchString: String!) {
        um_searchUnits(searchString: $searchString) {
          unit_id
          compid
          account {
            title
          }
          conduit_id
          hid
          unittype_id
          imei
          serial
          rgeo
          simnumber
          simphone
          lastmovetime
          laststoptime
          simactive
          drive_off_compid
          next_renew_date
          expired_confirmed
          eot_version
          eot_retry
          alert_sent_7
          alert_sent_30
          alert_sent_60
          expiration_alert_sent
          subscription_cycle
          label
          timezone
          function
          programming
          yearly_price
          reseller_compid
          reseller_markup
          dealer_compid
          dealer_markup
          kore_status
          last_activated
          about_unit
          pings_enabled
          unlimited_pings
          preload
          salesrep
          category
          vzw_vpn
          vzw_bucket
          group_id
          conduit_status
          pu_status
          simtype_id
          bucket_id
          manufacturerPO
          carrierstatus_id
          active
          ignitiontype
          starterinterrupt
          trackingmode
          trackingexpire
          lastreportedvoltage
          repotoken
          repotokenexpire
          unittype {
            unittype_id
            carrier_id
            name
            details
          }
          createdAt
        }
      }`;
      variables = {
        searchString: this.state.searchString
      };
      this.setState({searchingUnits: true});
      console.log('Units search vars:', variables);
      this.props.client.query({query, variables})
      .then((res) => {
        console.log('Units search results:', res);
        this.setState({searchingUnits: false, searchUnits: res.data.um_searchUnits})
      })
      .catch((err) => {
        console.error('Error with units search:', err);
      });

      // Search Vehicles
      query = gql`query um_searchVehicles ($searchString: String!) {
        um_searchVehicles (searchString: $searchString) {
          vehicle_id
          compid
          unit_id
          unit {
            unit_id
            compid
            account {
              title
            }
            conduit_id
            hid
            unittype_id
            imei
            serial
            rgeo
            simnumber
            simphone
            lastmovetime
            laststoptime
            simactive
            drive_off_compid
            next_renew_date
            expired_confirmed
            eot_version
            eot_retry
            alert_sent_7
            alert_sent_30
            alert_sent_60
            expiration_alert_sent
            subscription_cycle
            label
            timezone
            function
            programming
            yearly_price
            reseller_compid
            reseller_markup
            dealer_compid
            dealer_markup
            kore_status
            last_activated
            about_unit
            pings_enabled
            unlimited_pings
            preload
            salesrep
            category
            vzw_vpn
            vzw_bucket
            group_id
            conduit_status
            pu_status
            simtype_id
            bucket_id
            manufacturerPO
            carrierstatus_id
            active
            ignitiontype
            starterinterrupt
            trackingmode
            trackingexpire
            lastreportedvoltage
            repotoken
            repotokenexpire
            unittype {
              unittype_id
              carrier_id
              name
              details
            }
            createdAt
          }
          label
          vehiclemake_id
          model
          year
          color
          vin
          stocknumber
          license
          address
          notes
          installed
          icon
          odometer
        }
      }`;
      this.setState({searchingVehicles: true});
      console.log('Vehicles search vars:', variables);
      this.props.client.query({query, variables})
      .then((res) => {
        console.log('Vehicles search results:', res);
        this.setState({searchingVehicles: false, searchVehicles: res.data.um_searchVehicles})
      })
      .catch((err) => {
        console.error('Error searching for vehicles:', err);
      });

      // Search Inventory
      query = gql`query um_searchInventory ($searchString: String!) {
        um_searchInventory (searchString: $searchString) {
          unit_id
          compid
          account {
            title
          }
          conduit_id
          hid
          unittype_id
          imei
          serial
          rgeo
          simnumber
          simphone
          lastmovetime
          laststoptime
          simactive
          drive_off_compid
          next_renew_date
          expired_confirmed
          eot_version
          eot_retry
          alert_sent_7
          alert_sent_30
          alert_sent_60
          expiration_alert_sent
          subscription_cycle
          label
          timezone
          function
          programming
          yearly_price
          reseller_compid
          reseller_markup
          dealer_compid
          dealer_markup
          kore_status
          last_activated
          about_unit
          pings_enabled
          unlimited_pings
          preload
          salesrep
          category
          vzw_vpn
          vzw_bucket
          group_id
          conduit_status
          pu_status
          simtype_id
          bucket_id
          manufacturerPO
          carrierstatus_id
          active
          ignitiontype
          starterinterrupt
          trackingmode
          trackingexpire
          lastreportedvoltage
          repotoken
          repotokenexpire
          unittype {
            unittype_id
            carrier_id
            name
            details
          }
          createdAt
        }
      }`;
      this.setState({searchingInventory: true});
      console.log('Inventory search vars:', variables);
      this.props.client.query({query, variables})
      .then((res) => {
        console.log('Inventory search results:', res);
        this.setState({searchingInventory: false, searchInventory: res.data.um_searchInventory})
      })
      .catch((err) => {
        console.error('Error searching for inventory:', err);
      });
    }
  }, 500);

  handleSearch(searchString) {
    this.setState({searchString});

    console.log('typing');

    this.doSearch();
  }

  clearAndResetSearch() {
    this.setState({
      showSearchModal: false,
      searchString: '',
      searchingAccounts: false,
      searchAccounts: null,
      searchingUnits: false,
      searchUnits: null,
      searchingVehicles: false,
      searchVehicles: null,
      searchingInventory: false,
      searchInventory: null,
    });
  }

  renderProfileModal() {
    return (
      <Modal show={this.state.showProfileModal} keyboard>
        <Modal.Header closeButton={false}><Modal.Title><Icon icon="id-card" /> Profile</Modal.Title></Modal.Header>
        <Modal.Body>
          <Form fluid>
            <FormGroup>
              <ControlLabel>Title</ControlLabel>
              <Input
                name="title"
                placeholder="title"
                value={this.state.profile.title ? this.state.profile.title : ''}
                onChange={(e) => {
                  this.setState({
                    profile: {
                      ...this.state.profile,
                      title: e,
                    }
                  });
                }}
                disabled={this.state.saveProfileButtonDisabled}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Username</ControlLabel>
              <Input
                name="username"
                placeholder="username"
                value={this.state.profile.username ? this.state.profile.username : ''}
                onChange={(e) => {
                  this.setState({
                    profile: {
                      ...this.state.profile,
                      username: e,
                    }
                  });
                }}
                disabled={this.state.saveProfileButtonDisabled}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Password</ControlLabel>
              <InputGroup>
                <InputGroup.Addon><Icon icon="key" /></InputGroup.Addon>
                <Input
                  name="password"
                  placeholder="new password"
                  type="password"
                  value={this.state.profile.password ? this.state.profile.password : ''}
                  onChange={(e) => {
                    this.setState({
                      profile: {
                        ...this.state.profile,
                        password: e,
                      }
                    });
                  }}
                  disabled={this.state.saveProfileButtonDisabled}
                />
              </InputGroup>
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button appearance="primary" color="green" onClick={() => {this.saveProfile()}} disabled={this.state.saveProfileButtonDisabled}><Icon icon="save" /> {this.state.saveProfileButtonText}</Button>
          <Button appearance="subtle" onClick={() => {this.setState({showProfileModal: false})}} disabled={this.state.saveProfileButtonDisabled}><Icon icon="ban" /> Cancel</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  renderCompanyModal() {
    return (
      <Modal fluid show={this.state.showCompanyModal}>
        <Modal.Header closeButton={false}>
          <Modal.Title>Company Details</Modal.Title>          
        </Modal.Header>
        <Modal.Body>
          <Form>
            <FormGroup>
              <ControlLabel>Title</ControlLabel>
              <Input
                value={this.state.profile.account && this.state.profile.account.title ? this.state.profile.account.title : ''}
                onChange={e => {
                  this.setState({
                    profile: {
                      ...this.state.profile,
                      account: {
                        ...this.state.profile.account,
                        title: e,
                      }
                    }
                  })
                }}
              />
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button appearance="primary" color="green" onClick={() => {this.saveCompany()}} disabled={this.state.saveCompanyButtonDisabled}><Icon icon="save" /> {this.state.saveCompanyButtonText}</Button>
          <Button appearance="subtle" onClick={() => {this.setState({showCompanyModal: false})}} disabled={this.state.saveCompanyButtonDisabled}><Icon icon="ban" /> Cancel</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  renderLogDrawer() {
    return (
      <Drawer
        keyboard
        show={this.state.showLogModal}
        onHide={() => {this.setState({showLogModal: false})}}
        onShow={() => {this.props.log.refetch()}}
      >
        <Drawer.Header>
          <Drawer.Title><Icon icon="list" /> Action Log</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <Timeline>
            {
              this.props.log && this.props.log.umLogs && this.props.log.umLogs.length > 0
              ? <React.Fragment>
                {
                  this.props.log.umLogs.map(log => {
                    return <Timeline.Item key={'TimelineItem-' + log.log_id} dot={<Icon icon={log.action == 'create' ? 'plus-square' : log.action == 'read' ? 'eye' : log.action == 'update' ? 'pencil-square' : log.action == 'delete' ? 'minus-square' : 'circle'} />}>
                    <p><b>{moment.utc(log.createdAt).format('YYYY/MM/DD hh:mm A')}</b> - {log.action.toUpperCase()} {log.type}</p>
                    {
                      log.details && log.details.length > 0
                      ? <p>{log.details}</p>
                      : ''
                    }
                  </Timeline.Item>
                  })
                }
              </React.Fragment>
              : <Timeline.Item>No Actions Logged</Timeline.Item>
            }
          </Timeline>
        </Drawer.Body>
      </Drawer>
    );
  }

  renderSearchModal() {
    return (
      <Drawer
        size="lg"
        keyboard
        show={this.state.showSearchModal}
        onHide={() => {
          this.clearAndResetSearch();
        }}
      >
        <Drawer.Header>
          <Drawer.Title>
            <InputGroup>
              <InputGroup.Addon><Icon icon="search" /></InputGroup.Addon>
              <Input
                autoFocus
                placeholder="Search for units and companies"
                value={this.state.searchString}
                onChange={e => {this.handleSearch(e)}}
              />
            </InputGroup>
          </Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <Panel header={<h4><Icon icon="star" /> Customers</h4>}>
          {
            this.state.searchingAccounts
            ? <Loader content="Searching..." />
            : this.state.searchAccounts != null
              ? this.state.searchAccounts.length > 0
                ? this.state.searchAccounts.map(account => <Container><Link to={'/customer/'+account.compid} onClick={() => {this.clearAndResetSearch()}}>{account.title}</Link></Container>)
                : <span>No results</span>
              : <span>No results</span>
          }
          </Panel>
          <Panel header={<h4><Icon icon="archive" /> Inventory</h4>}>
          {
            this.state.searchingInventory
            ? <Loader content="Searching..." />
            : this.state.searchInventory != null
              ? this.state.searchInventory.length > 0
                ? this.state.searchInventory.map(unit => <Container><Link to={'/inventory/'+unit.unit_id} onClick={() => {this.clearAndResetSearch()}}>{unit.label}</Link></Container>)
                : <span>No results</span>
              : <span>No results</span>
          }
          </Panel>
          <Panel header={<h4><Icon icon="location-arrow" /> Units</h4>}>
          {
            this.state.searchingUnits
            ? <Loader content="Searching..." />
            : this.state.searchUnits != null
              ? this.state.searchUnits.length > 0
                ? <Table data={this.state.searchUnits} autoHeight>
                  <Table.Column width={200}>
                    <Table.HeaderCell>Customer</Table.HeaderCell>
                    <Table.Cell>
                    {
                      unit => {
                        return <Link to={'/customer/'+unit.compid} onClick={() => {this.clearAndResetSearch()}}>{unit.account.title}</Link>
                      }  
                    }
                    </Table.Cell>
                  </Table.Column>
                  <Table.Column width={200}>
                    <Table.HeaderCell>Unit</Table.HeaderCell>
                    <Table.Cell>
                    {
                      unit => {
                        return <Link to={'/customer/'+unit.compid+'/units/'+unit.unit_id} onClick={() => {this.clearAndResetSearch()}}>{unit.label}</Link>
                      }  
                    }
                    </Table.Cell>
                  </Table.Column>
                </Table>
                : <span>No results</span>
              : <span>No results</span>
          }
          </Panel>
          <Panel header={<h4><Icon icon="car" /> Vehicles</h4>}>
          {
            this.state.searchingVehicles
            ? <Loader content="Searching..." />
            : this.state.searchVehicles != null
              ? this.state.searchVehicles.length > 0
                ? <Table data={this.state.searchVehicles} autoHeight>
                  <Table.Column width={200}>
                    <Table.HeaderCell>Customer</Table.HeaderCell>
                    <Table.Cell>
                    {
                      vehicle => {
                        return <Link to={'/customer/'+vehicle.unit.compid} onClick={() => {this.clearAndResetSearch()}}>{vehicle.unit.account.title}</Link>
                      }  
                    }
                    </Table.Cell>
                  </Table.Column>
                  <Table.Column width={200}>
                    <Table.HeaderCell>Unit</Table.HeaderCell>
                    <Table.Cell>
                    {
                      vehicle => {
                        return <Link to={'/customer/'+vehicle.unit.compid+'/units/'+vehicle.unit.unit_id} onClick={() => {this.clearAndResetSearch()}}>{vehicle.unit.label}</Link>
                      }  
                    }
                    </Table.Cell>
                  </Table.Column>
                  <Table.Column width={200}>
                    <Table.HeaderCell>Vehicle</Table.HeaderCell>
                    <Table.Cell>
                    {
                      vehicle => {
                        return <Link to={'/customer/'+vehicle.unit.compid+'/units/'+vehicle.unit.unit_id+'/vehicle'} onClick={() => {this.clearAndResetSearch()}}>{vehicle.label}</Link>
                      }  
                    }
                    </Table.Cell>
                  </Table.Column>
                </Table>
                : <span>No results</span>
              : <span>No results</span>
          }
          </Panel>
        </Drawer.Body>
      </Drawer>
    );
  }

  render() {
    return (
      <Container style={{paddingLeft: '75px', paddingRight: '75px', paddingBottom: '10px'}}>
        <FlexboxGrid justify="space-between">
          <FlexboxGrid.Item colSpan={12}><h2>Asset Manager</h2></FlexboxGrid.Item>
          <FlexboxGrid.Item colSpan={12}>
            {
              !this.props.disabled
              ? <h2>{
                  this.props.user && this.props.user.umUserInfo && this.props.user.umUserInfo.account && this.props.user.umUserInfo.account.title
                  ? this.props.user.umUserInfo.account.title
                  : '...'
                }</h2>
              : <h2>Not logged in.</h2>
            }
          </FlexboxGrid.Item>
        </FlexboxGrid>
        <Navbar
          appearance='inverse' 
          className="navbar" 
          style={{
            borderRadius: '5px',
            backgroundColor: '#2a3e54',
            WebkitBoxShadow: '0px 2px 10px 0px rgba(0,0,0,0.5)',
            MozBoxShadow: '0px 2px 10px 0px rgba(0,0,0,0.5)',
            boxShadow: '0px 2px 10px 0px rgba(0,0,0,0.5)',
          }}
        >
          <Navbar.Header style={{padding: '10px'}}>
            {
              !this.props.disabled
              ? <img srcSet="images/logo.png 1x, images/logo@2x.png 2x" />
              : ''
            }
          </Navbar.Header>
          <Navbar.Body>
            {
              !this.props.disabled
              ? <Nav>
                <Nav.Item icon={<Icon icon="star" />} active={window.location.pathname == '/' || window.location.pathname.startsWith('/customer')} onClick={() => {this.navigateTo('/customers')}}>Customers</Nav.Item>
                <Nav.Item icon={<Icon icon="archive" />} active={window.location.pathname == '/inventory'} onClick={() => {this.navigateTo('/inventory')}}>Inventory</Nav.Item>
                <Nav.Item icon={<Icon icon="people-group" />} active={window.location.pathname == '/users'} onClick={() => {this.navigateTo('/users')}}>Users</Nav.Item>          
                {/* <Nav.Item icon={<Icon icon="lock" />} active={window.location.pathname == '/admin'} onClick={() => {this.navigateTo('/admin')}}>Admin</Nav.Item> */}
              </Nav>
              : <Nav appearance="subtle" />
            }
            {
              !this.props.disabled
              ? <Nav pullRight>
                <Nav.Item icon={<Icon icon="search" />} onClick={() => {this.setState({showSearchModal: true})}} />
                <Nav.Item icon={<Icon icon="cog" />} onClick={() => {this.setState({showCompanyModal: true})}} />
                <Nav.Item icon={<Icon icon="list" />} onClick={() => {this.setState({showLogModal: true})}} />
                {/*
                <Whisper trigger="click" speaker={
                  <Popover title="Action Log">
                    <Timeline width={300}>
                      {
                        this.props.log && this.props.log.umLogs && this.props.log.umLogs.length > 0
                        ? <React.Fragment>
                          {
                            this.props.log.umLogs.map(log => {
                              return <Timeline.Item dot={<Icon icon={log.action == 'create' ? 'plus-square' : log.action == 'read' ? 'eye' : log.action == 'update' ? 'pencil-square' : log.action == 'delete' ? 'minus-square' : 'circle'} />}>
                              <p>{moment.utc(log.createdAt).format('YYYY/MM/DD hh:mmA')}</p>
                              <p>{log.action.toUpperCase()} {log.type}</p>
                              <p>{log.details}</p>
                            </Timeline.Item>
                            })
                          }
                        </React.Fragment>
                        : <Timeline.Item>No Actions Logged</Timeline.Item>
                      }
                    </Timeline>
                  </Popover>
                }>
                  <Nav.Item icon={<Icon icon="list" />} />
                </Whisper>
                */}
                <Dropdown icon={<Icon icon="avatar" />} title={this.props.user && this.props.user.umUserInfo && this.props.user.umUserInfo && this.props.user.umUserInfo.title ? this.props.user.umUserInfo.title : '...'}>
                  <Dropdown.Item icon={<Icon icon="id-card" />} onClick={() => {this.setState({showProfileModal: true})}}>Profile</Dropdown.Item>
                  <Dropdown.Item divider />
                  <Dropdown.Item icon={<Icon icon="sign-out" />} onClick={() => {this.doLogout()}}>Logout</Dropdown.Item>
                </Dropdown>
              </Nav>
              : <Nav pullRight />
            }            
          </Navbar.Body>
        </Navbar>
        {this.renderProfileModal()}
        {this.renderCompanyModal()}
        {this.renderLogDrawer()}
        {this.renderSearchModal()}
      </Container>
    );
  }
}

export default compose(
  withApollo,
  graphql(USER, {
    name: 'user',
  }),
  graphql(LOG, {
    name: 'log',
  }),
)(Header);