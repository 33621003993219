import React from 'react';

import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Container,
  ControlLabel,
  FlexboxGrid,
  Form,
  FormGroup,
  HelpBlock,
  Icon,
  Input,
  InputGroup,
  InputPicker,
  Loader,
  Modal,
  Nav,
  Table,
  Toggle,
  Tooltip,
  Tree,
  Sidenav,
  Whisper,
} from 'rsuite';
import { compose, graphql, withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import timezones from 'compact-timezone-list';
import sha1 from 'sha1';

import Breadcrumbs from '../../Components/Breadcrumbs';
import queries from './queries';

class UserActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmDelete: false,
      working: false,
    }

    this.handleDeleteAccount = this.handleDeleteAccount.bind(this);
    this.doDeleteAccount = this.doDeleteAccount.bind(this);
  }

  handleDeleteAccount() {
    this.setState({
      showConfirmDelete: true,
    });
  }

  doDeleteAccount() {
    this.setState({
      working: true
    });

    const mutation = gql`
    mutation um_deleteAccount ($compid: Int!) {
      um_deleteAccount (compid: $compid)
    }`;
    const variables = {
      compid: this.props.rowData.compid,
    }

    this.props.client.mutate({mutation, variables})
    .then((res) => {
      console.log('delete account success:', res);
      this.props.onDelete();
      this.setState({
        working: false
      });
    })
    .catch((err) => {
      console.log('delete account error:', err);
    })
  }

  render() {
    return (
      !this.state.working
      ? !this.state.showConfirmDelete
        ? <React.Fragment>
          <Whisper trigger="hover" placement="top" speaker={<Tooltip>{'Log in as ' + this.props.rowData.title}</Tooltip>}>
            <span><a href={'https://tracking.gpsleaders.com/quicklogin/'+this.props.rowData.masterUser.sm} target="_blank"><Icon icon="sign-in" /></a> </span>
          </Whisper>
          {
            this.props.rowData.unitCount == 0
            ? <span>
              {/* <a onClick={this.props.handleEdit.bind(this)}><Icon icon="pencil" /></a>&nbsp; */}
              <a onClick={this.handleDeleteAccount}><Icon icon="trash" /></a>
            </span>
            : ''
          }
          </React.Fragment>
        : <ButtonGroup>
            <Button size="xs" color="red" onClick={this.doDeleteAccount}><Icon icon="trash" /> Confirm</Button>
            <Button size="xs" onClick={()=>{this.setState({showConfirmDelete: false})}}><Icon icon="ban" /> Cancel</Button>
          </ButtonGroup>
      : <Loader title="Working..." />
    );
  }
}

class Customers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showNewAccountModal: false,
      showAccountDetailModal: false,
      accountDetailTitle: '',
      accountDetailActionButtonText: '',
      editAccount: null,
      newAccount: {
        title: '',
      },
    };

    this.handleNewAccountFieldChange = this.handleNewAccountFieldChange.bind(this);
    this.renderNewAccountModal = this.renderNewAccountModal.bind(this);
    this.validateNewAccountFields = this.validateNewAccountFields.bind(this);
    this.addAccount = this.addAccount.bind(this);
  }

  componentDidMount() {}

  componentWillReceiveProps(props) {
    console.log('Props:', props);
  }

  handleNewAccountFieldChange(field, value) {
    let newAccount = Object.assign({}, this.state.newAccount);
    newAccount[field] = value;
    this.setState({newAccount});
  }

  renderNewAccountModal() {
    return <Modal
      show={this.state.showNewAccountModal}
      keyboard
      onExit={() => {this.setState({showAccountDetailModal: false})}}
    >
      <Modal.Header closeButton={false}>
        <Modal.Title><Icon icon="star" /> New Customer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form fluid>
          <FormGroup>
            <ControlLabel>Business Name</ControlLabel>
            <InputGroup>
              <InputGroup.Addon><Icon icon="star" /></InputGroup.Addon>
              <Input placeholder="Business Name" value={this.state.newAccount.title} onChange={e => {this.handleNewAccountFieldChange('title', e)}} />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <ControlLabel>Asset Manager Access</ControlLabel>
            <Toggle
              checkedChildren="Enabled"
              unCheckedChildren="Disabled"
              checked={this.state.newAccount.assman}
              onChange={assman => {
                this.setState({
                  newAccount: {
                    ...this.state.newAccount,
                    assman
                  }
                });
              }}
            />
            {
              this.state.newAccount.assman
              ? <HelpBlock>An Asset Manager account will be created.</HelpBlock>
              : ''
            }
          </FormGroup>
          <FormGroup>
            <ControlLabel>Primary User</ControlLabel>
            <Input placeholder="First Name" value={this.state.newAccount.firstname} onChange={e => {this.handleNewAccountFieldChange('firstname', e)}} />
          </FormGroup>
          <FormGroup>
            <Input placeholder="Last Name" value={this.state.newAccount.lastname} onChange={e => {this.handleNewAccountFieldChange('lastname', e)}} />
          </FormGroup>
          <FormGroup>
            <InputGroup>
              <InputGroup.Addon><Icon icon="user" /></InputGroup.Addon>
              <Input placeholder="Username" value={this.state.newAccount.username} onChange={e => {this.handleNewAccountFieldChange('username', e)}} />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup>
              <InputGroup.Addon><Icon icon="key" /></InputGroup.Addon>
              <Input placeholder="Password" type="password" value={this.state.newAccount.password} onChange={e => {this.handleNewAccountFieldChange('password', e)}} />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup>
              <InputGroup.Addon><Icon icon="envelope" /></InputGroup.Addon>
              <Input placeholder="Email" value={this.state.newAccount.email} onChange={e => {this.handleNewAccountFieldChange('email', e)}} />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup>
              <InputGroup.Addon><Icon icon="phone" /></InputGroup.Addon>
              <Input placeholder="Phone" value={this.state.newAccount.phone} onChange={e => {this.handleNewAccountFieldChange('phone', e)}} />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup>              
              <Input componentClass="textarea" rows={3} placeholder="Address" value={this.state.newAccount.address} onChange={e => {this.handleNewAccountFieldChange('address', e)}} />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <ControlLabel>Timezone</ControlLabel>
            <InputPicker cleanable={false} data={timezones.map(tz=>({value: tz.tzCode, label: tz.tzCode}))} value={this.state.newAccount.timezone} onChange={e => {this.handleNewAccountFieldChange('timezone', e)}} />
          </FormGroup>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button appearance="primary" color="green" onClick={this.addAccount} disabled={!this.validateNewAccountFields()}><Icon icon="save" /> Add</Button>
        <Button appearance="subtle" onClick={() => {this.setState({showNewAccountModal: false})}}><Icon icon="ban" /> Cancel</Button>
      </Modal.Footer>
    </Modal>;
  }

  validateNewAccountFields() {
    return this.state.newAccount
    && this.state.newAccount.title
    && this.state.newAccount.title.length > 0
    && this.state.newAccount.firstname
    && this.state.newAccount.firstname.length > 0
    && this.state.newAccount.lastname
    && this.state.newAccount.lastname.length > 0
    && this.state.newAccount.username
    && this.state.newAccount.username.length > 0
    && this.state.newAccount.password
    && this.state.newAccount.password.length > 0
    && this.state.newAccount.email
    && this.state.newAccount.email.length > 0
    && this.state.newAccount.phone
    && this.state.newAccount.phone.length > 0
    && this.state.newAccount.address
    && this.state.newAccount.address.length > 0
  }

  addAccount() {
    const mutation = gql`mutation um_addAccount(
      $title: String!,
      $firstname: String!,
      $lastname: String!,
      $username: String!,
      $password: String!,
      $email: String!,
      $phone: String!,
      $address: String!,
      $timezone: String!,
      $assman: Boolean!
    ) {
      um_addAccount (
        title: $title,
        firstname: $firstname,
        lastname: $lastname,
        username: $username,
        password: $password,
        email: $email,
        phone: $phone,
        address: $address,
        timezone: $timezone,
        assman: $assman,
      )
    }`;
    const variables = {
      ...this.state.newAccount,
      password: sha1(this.state.newAccount.password),
    };

    console.log('VARS:', variables);

    this.props.client.mutate({mutation, variables})
    .then((res) => {
      console.log('Add Account RES:', res);
      this.setState({
        showNewAccountModal: false,
      });
      this.props.accounts.refetch();
    })
    .catch((err) => {
      console.error('Add Account ERROR:', err);
    });
  }

  render() {
    return <Container>
      <Breadcrumbs
        pages={[{
          url: '/customers',
          name: 'Customers',
        }]}
      />
      <Container className="content">
        <Container className="content-body">
          <ButtonToolbar>
            <ButtonGroup>
              <Button
                appearance="primary"
                onClick={() => {
                  this.setState({
                    showNewAccountModal: true,
                    newAccount: {
                      firstname: '',
                      lastname: '',
                      username: '',
                      password: '',
                      email: '',
                      phone: '',
                      address: '',
                      timezone: moment.tz.guess()
                    },
                  });
                }}
              ><Icon icon="plus" /> New</Button>
            </ButtonGroup>
          </ButtonToolbar>
          {
            this.props.accounts.um_accounts
            ? <Table
              autoHeight
              data={this.props.accounts.um_accounts}
            >
              <Table.Column fixed width={100}>
                <Table.HeaderCell>ID</Table.HeaderCell>
                <Table.Cell dataKey="compid" />
              </Table.Column>
              <Table.Column resizable width={200}>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.Cell>{account => (<Link to={'/customer/'+account.compid}>{account.title}</Link>)}</Table.Cell>
              </Table.Column>
              <Table.Column resizable width={200}>
                <Table.HeaderCell>Primary User</Table.HeaderCell>
                <Table.Cell dataKey="masterUser.fullname" />
              </Table.Column>
              <Table.Column resizable width={200}>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.Cell dataKey="masterUser.email" />
              </Table.Column>
              <Table.Column resizable width={150}>
                <Table.HeaderCell>Phone</Table.HeaderCell>
                <Table.Cell dataKey="masterUser.phone" />
              </Table.Column>
              <Table.Column resizable width={100}>
                <Table.HeaderCell>City</Table.HeaderCell>
                <Table.Cell dataKey="masterUser.city" />
              </Table.Column>
              <Table.Column resizable width={100}>
                <Table.HeaderCell>State</Table.HeaderCell>
                <Table.Cell dataKey="masterUser.state" />
              </Table.Column>
              <Table.Column resizable width={100}>
                <Table.HeaderCell>Devices</Table.HeaderCell>
                <Table.Cell dataKey="unitCount" />
              </Table.Column>
              <Table.Column resizable width={100}>
                <Table.HeaderCell>Installed</Table.HeaderCell>
                <Table.Cell dataKey="installedUnitCount" />
              </Table.Column>
              <Table.Column resizable width={100}>
                <Table.HeaderCell>Uninstalled</Table.HeaderCell>
                <Table.Cell>{rowData=>rowData.unitCount-rowData.installedUnitCount}</Table.Cell>
              </Table.Column>
              <Table.Column width={200}>
                <Table.HeaderCell><Icon icon="cogs" /></Table.HeaderCell>
                <Table.Cell>
                  {
                    rowData => {
                      function handleEdit() {
                        /*
                        this.setState({
                          showUnitDetailModal: true,
                          unitDetailModelTitle: <React.Fragment><Icon icon="location-arrow" /> {rowData.label}</React.Fragment>,
                          unitDetailActionButtonText: <React.Fragment><Icon icon="save" /> Save</React.Fragment>,
                          editUnit: rowData,
                        });
                        */
                      }

                      return <UserActions rowData={rowData} handleEdit={handleEdit.bind(this)} client={this.props.client} onDelete={() => {this.props.accounts.refetch()}} />;  
                    }
                  }
                </Table.Cell>
              </Table.Column>
            </Table>
            : <Loader content="Loading..." />          
          }
        </Container>
      </Container>
      {this.renderNewAccountModal()}
    </Container>;
  }
}

export default compose(
  graphql(queries.accounts, {
    name: 'accounts'
  }),
  graphql(queries.account, {
    name: 'account'
  }),
)(withApollo(Customers));