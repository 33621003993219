const References = {
  build: '1.17',
  terms: [
    {
      value: 12,
      label: '1 Year',
    }, {
      value: 24,
      label: '2 Years',
    }, {
      value: 36,
      label: '3 Years',
    }, {
      value: 48,
      label: '4 Years',
    }, {
      value: 60,
      label: '5 Years',
    }, {
      value: 1,
      label: 'Month to Month',
    },
  ],
};

export default References;