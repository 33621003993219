import React from 'react';
import gql from 'graphql-tag';
import { compose, graphql, withApollo } from 'react-apollo';
import { Container } from 'rsuite';

import Breadcrumbs from '../../Components/Breadcrumbs';

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentWillReceiveProps(props) {}

  render() {
    return <Container>
      <Breadcrumbs
        pages={[{
          url: '/admin',
          name: 'Admin'
        }]}
      />
      <Container className="content">
        <Container className="content-body">ADMIN</Container>
      </Container>
    </Container>;
  }
}

export default withApollo(Admin);