import React from 'react';

import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Container,
  ControlLabel,
  Form,
  FormGroup,
  Icon,
  Input,
  InputGroup,
  Loader,
  Modal,
  Table,
  Tag,
  TagGroup,
  Toggle,
} from 'rsuite';

import { compose, graphql, withApollo } from 'react-apollo';
import gql from 'graphql-tag';

import sha1 from 'sha1';

import Breadcrumbs from '../../Components/Breadcrumbs';

const USERS_QUERY = gql`
query {
  umUsers {
    umuser_id
    compid
    username
    password
    title
    logo
    dealer_id
    email_server
    google_map_key
    business_name
    domain_name
    alternate_domain
    login_page_path
    email_domain
    active
    roles {
      umrole_id
      name
    }
  }
}`;

const ROLES_QUERY = gql`
query {
  umRoles {
    umrole_id
    name
  }
}`;

class UserActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmDelete: false,
      working: false,
    }

    this.handleDeleteUser = this.handleDeleteUser.bind(this);
    this.doDeleteUser = this.doDeleteUser.bind(this);
  }

  handleDeleteUser() {
    this.setState({
      showConfirmDelete: true,
    });
  }

  doDeleteUser() {
    this.setState({
      working: true
    });

    const mutation = gql`
    mutation deleteUmUser ($umuser_id: Int!) {
      deleteUmUser (umuser_id: $umuser_id)
    }`;
    const variables = {
      umuser_id: this.props.rowData.umuser_id,
    }

    this.props.client.mutate({mutation, variables})
    .then((res) => {
      console.log('delete user success:', res);
      this.props.onDelete();
      this.setState({
        working: false
      });
    })
    .catch((err) => {
      console.log('delete user error:', err);
    })
  }

  render() {
    return (
      !this.state.working
      ? !this.state.showConfirmDelete
        ? <span>
          <a onClick={this.props.handleEdit.bind(this)}><Icon icon="pencil" /></a>&nbsp;
          <a onClick={this.handleDeleteUser}><Icon icon="trash" /></a>
        </span>
        : <ButtonGroup>
            <Button size="xs" color="red" onClick={this.doDeleteUser}><Icon icon="trash" /> Confirm</Button>
            <Button size="xs" onClick={()=>{this.setState({showConfirmDelete: false})}}><Icon icon="ban" /> Cancel</Button>
          </ButtonGroup>
      : <Loader title="Working..." />
    );
  }
}

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showUserDetailModal: false,
      userDetailModelTitle: '',
      userDetailActionButtonText: '',
      userDetailButtonsDisabled: false,
      editUser: null,
      newUser: {roles: []},
    };

    this.handleModifyUserDetailField = this.handleModifyUserDetailField.bind(this);
    this.saveUserDetail = this.saveUserDetail.bind(this);
  }

  componentDidMount() {}

  componentWillReceiveProps(props) {
    console.log('props:', props);
  }

  handleModifyUserDetailField(field, value) {
    if (this.state.editUser != null) {
      let editUser = Object.assign({}, this.state.editUser);
      editUser[field] = value;
      this.setState({editUser});
    } else {
      let newUser = Object.assign({}, this.state.newUser);
      newUser[field] = value;
      this.setState({newUser});
    }
  }

  saveUserDetail() {
    //console.log('saveUserDetail');
    //console.log('State:', this.state);
    if (this.state.editUser != null) {
      console.log('edit');
      let mutation = gql`mutation updateUmUser ($umuser_id: Int!, $title: String!, $username: String!, $roles: [Int!]) {
        updateUmUser (umuser_id: $umuser_id, title: $title, username: $username, roles: $roles)
      }`;
      let variables = {
        ...this.state.editUser,        
        roles: this.state.editUser.roles.reduce((accum, role, index) => (role == true ? [...accum, this.props.roles.umRoles[index].umrole_id] : accum), [])
      };
      if (this.state.editUser.password.length > 0) {
        mutation = gql`mutation updateUmUser ($umuser_id: Int!, $title: String!, $username: String!, $password: String!, $roles: [Int!]) {
          updateUmUser (umuser_id: $umuser_id, title: $title, username: $username, password: $password, roles: $roles)
        }`;
        variables.password = sha1(this.state.editUser.password);
      }

      console.log('VARS:', variables);

      this.props.client.mutate({mutation, variables})
      .then((res) => {
        this.props.users.refetch();
        this.setState({
          showUserDetailModal: false
        });
      })
      .catch((err) => {
        console.error('Error edit user:', err);
      });
    } else {
      console.log('new');
      const mutation = gql`mutation addUmUser ($title: String!, $username: String!, $password: String!, $roles: [Int!]) {
        addUmUser (title: $title, username: $username, password: $password, roles: $roles)
      }`;
      const variables = {
        ...this.state.newUser,
        password: sha1(this.state.newUser.password),
        roles: this.state.newUser.roles.reduce((accum, role, index) => (role == true ? [...accum, this.props.roles.umRoles[index].umrole_id] : accum), [])
      };

      console.log('VARS:', variables);

      this.props.client.mutate({mutation, variables})
      .then((res) => {
        this.props.users.refetch();
        this.setState({
          showUserDetailModal: false
        });
      })
      .catch((err) => {
        console.error('Error creating new user:', err);
      });
    }
  }

  render() {
    const users = this.props.users && this.props.users.umUsers
    ? this.props.users.umUsers
    : null;

    return <Container>
      <Breadcrumbs
        pages={[{
          url: '/user',
          name: 'Users',
        }]}
      />
      <Container className="content">
        <Container className="content-body">
        <ButtonToolbar>
          <ButtonGroup>
            <Button
              appearance="primary"
              onClick={() => {
                this.setState({
                  showUserDetailModal: true,
                  userDetailModelTitle: <React.Fragment><Icon icon="avatar" /> New User</React.Fragment>,
                  userDetailActionButtonText: <React.Fragment><Icon icon="save" /> Add</React.Fragment>,
                  editUser: null,
                  newUser: {
                    roles: this.props.roles.umRoles.map(r=>false)
                  },
                });
              }}
            ><Icon icon="plus" /> New</Button>
          </ButtonGroup>
        </ButtonToolbar>
        {
          users
          ? <Table
            autoHeight
            data={users}
          >
            <Table.Column width={200}>
              <Table.HeaderCell>Display Name</Table.HeaderCell>
              <Table.Cell dataKey="title" />
            </Table.Column>
            <Table.Column width={200}>
              <Table.HeaderCell>Username</Table.HeaderCell>
              <Table.Cell dataKey="username" />
            </Table.Column>
            <Table.Column width={200}>
              <Table.HeaderCell>Roles</Table.HeaderCell>
              <Table.Cell>                
                {                  
                  rowData => {
                    return rowData.roles && rowData.roles.length > 0
                    ? <TagGroup>
                      {
                        rowData.roles.map(role => (<Tag key={'tag-'+role.umrole_id} color="orange">{role.name}</Tag>))
                      }
                    </TagGroup>
                    : 'none';
                  }
                }                
              </Table.Cell>
            </Table.Column>
            <Table.Column width={200}>
              <Table.HeaderCell>Action</Table.HeaderCell>
              <Table.Cell>                
                {
                  rowData => {
                    function handleEdit() {
                      this.setState({
                        showUserDetailModal: true,
                        userDetailModelTitle: <React.Fragment><Icon icon="avatar" /> {rowData.title}</React.Fragment>,
                        userDetailActionButtonText: <React.Fragment><Icon icon="save" /> Save</React.Fragment>,
                        editUser: {
                          ...rowData,
                          password: '',
                          roles: this.props.roles.umRoles.map(r =>rowData.roles.reduce((accum, role, index) => {
                            console.log('Role:', role, r);
                            return role.umrole_id == r.umrole_id ? true : accum;
                          }, false)),
                        },
                      });
                    }

                    return <UserActions rowData={rowData} handleEdit={handleEdit.bind(this)} client={this.props.client} onDelete={() => {this.props.users.refetch()}} />;
                  }
                }
              </Table.Cell>
            </Table.Column>
          </Table>
          : <Loader content="Loading..." />
        }
        </Container>
      </Container>
      <Modal show={this.state.showUserDetailModal}>
        <Modal.Header closeButton={false}><Modal.Title>{this.state.userDetailModelTitle}</Modal.Title></Modal.Header>
        <Modal.Body>
          <Form fluid>
            <FormGroup>
              <ControlLabel>Display Name</ControlLabel>
              <Input
                name="title"
                placeholder="display name"
                value={this.state.editUser != null ? this.state.editUser.title : this.state.newUser.title}
                onChange={e => {this.handleModifyUserDetailField('title', e)}}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Username</ControlLabel>
              <InputGroup>
                <InputGroup.Addon><Icon icon="user" /></InputGroup.Addon>
                <Input
                  name="username"
                  placeholder="username"
                  value={this.state.editUser != null ? this.state.editUser.username : this.state.newUser.username}
                  onChange={e => {this.handleModifyUserDetailField('username', e)}}
                />
            </InputGroup>
            </FormGroup>
            <FormGroup>
              <ControlLabel>Password</ControlLabel>
              <InputGroup>
                <InputGroup.Addon><Icon icon="key" /></InputGroup.Addon>
                <Input
                  type="password"
                  name="password"
                  placeholder="password"
                  value={this.state.editUser != null ? this.state.editUser.password : this.state.newUser.password}
                  onChange={e => {this.handleModifyUserDetailField('password', e)}}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <ControlLabel>Roles</ControlLabel>
              {
                this.state.editUser != null
                ? this.state.editUser.roles.map((role, index) => {
                  return <Toggle
                    key={'toggle-'+index}
                    checked={role}
                    checkedChildren={this.props.roles.umRoles[index].name}
                    unCheckedChildren={this.props.roles.umRoles[index].name}
                    onChange={e => {
                      let roles = this.state.editUser.roles.concat();
                      roles[index] = e;
                      this.setState({
                        editUser: {
                          ...this.state.editUser,
                          roles,
                        }
                      });
                    }}
                    style={{marginRight: '5px'}}
                  />;
                })
                : this.state.newUser.roles.map((role, index) => {
                  return <Toggle
                    key={'toggle-'+index}
                    checked={role}
                    checkedChildren={this.props.roles.umRoles[index].name}
                    unCheckedChildren={this.props.roles.umRoles[index].name}
                    onChange={e => {
                      let roles = this.state.newUser.roles.concat();
                      roles[index] = e;
                      this.setState({
                        newUser: {
                          ...this.state.newUser,
                          roles,
                        }
                      });
                    }}
                    style={{marginRight: '5px'}}
                  />;
                })
              }
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button appearance="primary" color="green" onClick={() => {this.saveUserDetail()}} disabled={this.state.userDetailButtonsDisabled}>{this.state.userDetailActionButtonText}</Button>
          <Button appearance="subtle" onClick={() => {this.setState({showUserDetailModal: false})}} disabled={this.state.userDetailButtonsDisabled}><Icon icon="ban" /> Cancel</Button>
        </Modal.Footer>
      </Modal>
    </Container>;
  }
}

export default compose(
  graphql(USERS_QUERY, {
    name: 'users',
  }),
  graphql(ROLES_QUERY, {
    name: 'roles',
  })
)(withApollo(Users));