import React, { Component } from 'react';
import 'rsuite/dist/styles/rsuite.min.css';
import './App.css';

import { Route, withRouter } from 'react-router-dom';

import { compose, graphql, withApollo } from 'react-apollo';
import gql from 'graphql-tag';

import {
  Container,
  Content,
  Footer,
  Header,
} from 'rsuite';

import AppHeader from './Layout/Header';
import AppFooter from './Layout/Footer';
import Customers from './Layout/Customers/Customers';
import Customer from './Layout/Customers/Customer/Customer';
import Inventory from './Layout/Inventory/Inventory';
import Users from './Layout/Users/Users';
import Admin from './Layout/Admin/Admin';
import Login from './Layout/Login';
import Quicklogin from './Layout/Quicklogin/Quicklogin';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoggedIn: localStorage.getItem('GPSLeadersAssetManagerAuthToken') ? true : false
    };

    this.checkLoginStatus = this.checkLoginStatus.bind(this);
  }

  checkLoginStatus() {
    this.setState({
      isLoggedIn: localStorage.getItem('GPSLeadersAssetManagerAuthToken') != null ? true : false
    });
  }

  render() {
    return (
      <div className="App">
      {
        this.state.isLoggedIn
        ? <Container>
          <Header className="header">
            <AppHeader history={this.props.history} onLogout={this.checkLoginStatus}/>
          </Header>
          <Content style={{backgroundColor: '#e7e7ea'}}>            
            <Route path={['/', '/customers', '/login']} exact component={Customers} />
            <Route path="/customer/:compid" exact component={Customer} />
            <Route path="/customer/:compid/:tab" exact component={Customer} />
            <Route path="/customer/:compid/:tab/:id" exact component={Customer} />
            <Route path="/customer/:compid/:tab/:id/:subtab" exact component={Customer} />
            <Route path="/admin" exact component={Admin} />
            <Route path="/inventory" exact component={Inventory} />
            <Route path="/inventory/:unit_id" exact component={Inventory} />
            <Route path="/users" exact component={Users} />
            <Route path="/quicklogin/:token" exact component={props => <Quicklogin {...props} onLogin={this.checkLoginStatus} />} />
          </Content>
          <Footer>
            <AppFooter />
          </Footer>
        </Container>
        : <Container>
          <Header className="header">
            <AppHeader history={this.props.history} disabled={true}/>
          </Header>
          <Content style={{backgroundColor: '#e7e7ea'}}>
            <Route path={['/', '/login']} exact component={props => <Login {...props} onLogin={this.checkLoginStatus} />} />
            <Route path="/quicklogin/:token" exact component={props => <Quicklogin {...props} onLogin={this.checkLoginStatus} />} />
          </Content>
          <Footer>
            <AppFooter />
          </Footer>
        </Container>
      }
      </div>      
    );
  }
}

export default withRouter(App);
