import React from 'react';
import gql from 'graphql-tag';
import { compose, graphql, withApollo } from 'react-apollo';

import { 
  Container,
  Loader,
} from 'rsuite';

class Quicklogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    const mutation = gql`mutation um_quickLogin ($quicklogin_token: String!) {
      um_quickLogin (quicklogin_token: $quicklogin_token)
    }`;
    const variables = {
      quicklogin_token: props.match.params.token,
    };
    props.client.mutate({mutation, variables})
    .then((res) => {
      localStorage.setItem('GPSLeadersAssetManagerAuthToken', res.data.um_quickLogin);

      // redirect if needed
      if (window.location.pathname !== '/') {
        props.history.push('/');
      }

      props.onLogin();
    })
    .catch((err) => {
      console.error('Error with quicklogin using token:', props.match.params.token);
      console.error('Error:', err);
    })
  }

  componentDidMount() {}

  componentWillReceiveProps(props) {}

  render() {
    return <Container>
      <Container className="content">
        <Loader content="Logging in..." center size="lg" />
      </Container>
    </Container>;
  }
}

export default withApollo(Quicklogin);