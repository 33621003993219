import React from 'react';

import { Breadcrumb, Icon } from 'rsuite';

import { Link } from 'react-router-dom';

class Breadcrumbs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentWillReceiveProps(props) {}

  render() {
    return <Breadcrumb className="breadcrumbs">
      <Breadcrumb.Item><Icon icon='home' /></Breadcrumb.Item>
      {
        this.props.pages.map((page, index) => (<Breadcrumb.Item key={'BreadcrumbItem-' + index} active={index==this.props.pages.length-1}>{index==this.props.pages.length-1 ? page.name : <Link to={page.url}>{page.name}</Link>}</Breadcrumb.Item>))
      }
    </Breadcrumb>;
  }
}

export default Breadcrumbs;