import gql from 'graphql-tag';

const queries = {
  accounts: gql`query {
    um_accounts {
      compid
      title
      pings
      account_type
      dealer
      dealer_type
      account_tz
      email_settings
      maintenance_email
      account_permissions
      account_settings
      state_id
      eot_version
      eot_username
      eot_password
      eot_count
      creation_date
      markup
      security_code
      registered
      opt_out_code
      popups
      paypal_id
      batt_threshold
      root_compid
      defaultbucket_id
      masteruser_id
      installedUnitCount
      unitCount
      masterUser {
        user_id
        compid
        username
        password
        fullname
        firstname
        lastname
        email
        address
        phone
        dealer_login
        user_type
        master_account
        sm
        permissions
        timezone
      }
    }
  }`,
  account: gql`query {
    um_account {
      compid
      title
      pings
      account_type
      dealer
      dealer_type
      account_tz
      email_settings
      maintenance_email
      account_permissions
      account_settings
      state_id
      eot_version
      eot_username
      eot_password
      eot_count
      creation_date
      markup
      security_code
      registered
      opt_out_code
      popups
      paypal_id
      batt_threshold
      root_compid
      defaultbucket_id
      masteruser_id
    }
  }`,
};

export default queries;