import React from 'react';
import gql from 'graphql-tag';
import { compose, graphql, withApollo } from 'react-apollo';

import {
  ControlLabel,
  FormGroup,
  Loader,
  Icon,
  Input,
  InputGroup,
  Notification,
} from 'rsuite';

const DEFAULT_ACTION_BUTTON_TEXT = <React.Fragment><Icon icon="send" /> Send</React.Fragment>;
const SENDING_ACTION_BUTTON_TEXT = <React.Fragment><Icon icon="spinner" /> Sending</React.Fragment>

class UnitOta extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ota: '',
      actionButtonText: DEFAULT_ACTION_BUTTON_TEXT,
      actionButtonTextDisabled: false,
    };

    this.sendOta = this.sendOta.bind(this);
  }

  componentDidMount() {}

  componentWillReceiveProps(props) {}

  sendOta() {
    const mutation = gql`mutation um_addOta($unit_id: Int!, $ota: String!) {
      um_addOta(unit_id: $unit_id, ota: $ota)
    }`;

    this.props.units.forEach((unit) => {
      const variables = {
        unit_id: unit.unit_id,
        ota: this.state.ota,
      };
  
      this.setState({
        actionButtonTextDisabled: true,
        actionButtonText: SENDING_ACTION_BUTTON_TEXT,
      });
  
      this.props.client.mutate({mutation, variables})
      .then((res) => {
        if (res.data.um_addOta) {        
          Notification.success({
            title: 'OTA',
            description: 'OTA was successfully queued.'
          });
        } else {
          Notification.error({
            title: 'OTA',
            description: 'OTA could NOT be queued.',
          })
        }
        console.log('Sent OTA:', res);
        this.setState({
          actionButtonText: DEFAULT_ACTION_BUTTON_TEXT,
          actionButtonTextDisabled: false,
          ota: '',
        });
      })
      .catch((err) => {
        console.error('Error sending OTA:', err);
        Notification.error({
          title: 'OTA',
          description: 'OTA could NOT be queued.'
        });
      });
    });
  }

  render() {
    return <FormGroup>
      <ControlLabel>OTA</ControlLabel>
      <InputGroup>
        <Input
          placeholder="OTA Command"
          value={this.state.ota}
          onChange={ota => this.setState({ota})}
        />
        <InputGroup.Button
          appearance="primary"
          color="green"
          disabled={this.state.actionButtonTextDisabled || this.state.ota.length == 0}
          onClick={this.sendOta}
        >{this.state.actionButtonText}</InputGroup.Button>
      </InputGroup>
    </FormGroup>;
  }
}

export default withApollo(UnitOta);