import React from 'react';

import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import sha1 from 'sha1';

import {
  Alert,
  Button,
  ButtonToolbar,
  Container,
  ControlLabel,
  FlexboxGrid,
  Form,
  FormControl,
  FormGroup,
  Icon,
  Input,
  InputGroup,
  Message,
} from 'rsuite';

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
    };

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.doLogin = this.doLogin.bind(this);
  }

  componentDidMount() {}

  componentWillReceiveProps(props) {}

  handleKeyDown(e) {
    if (e.key === 'Enter') {
      this.doLogin();
    }
  }

  doLogin() {
    const mutation = gql`
    mutation dealerLogin ($username: String!, $password: String!) {
      dealerLogin (username: $username, password: $password)
    }`;
    const variables = {
      username: this.state.username,
      password: sha1(this.state.password)
    }

    this.props.client.mutate({mutation, variables})
    .then(res => {
      localStorage.setItem('GPSLeadersAssetManagerAuthToken', res.data.dealerLogin);
      
      // redirect if needed
      if (window.location.pathname !== '/') {
        this.props.history.push('/');
      }

      this.props.onLogin();
    })
    .catch(error => {
      console.log('ERROR:', error);
      Alert.error('Error logging in.');
    });
  }

  render() {
    return <FlexboxGrid justify="space-around">
      <FlexboxGrid.Item className="login">
        <Form>
          <Container style={{alignItems: 'center', paddingBottom: '15px', color: '#e7e7ea'}}><h1>LOG IN</h1></Container>
          <InputGroup inside size="lg" style={{width: 300, marginBottom: 10}}>
            <InputGroup.Addon>
              <Icon icon="avatar" />
            </InputGroup.Addon>
            <Input
              name="username"
              value={this.state.username}
              onKeyDown={this.handleKeyDown}
              onChange={username => {this.setState({username})}}
            />
          </InputGroup>
          <InputGroup inside size="lg" style={{width: 300, marginBottom: 10}}>
            <InputGroup.Addon>
              <Icon icon="key" />
            </InputGroup.Addon>
            <Input
              name="password"
              type="password"
              value={this.state.password}
              onKeyDown={this.handleKeyDown}
              onChange={password => {this.setState({password})}}
            />
          </InputGroup>
          <FormGroup>
            <Button appearance="primary" size="lg" onClick={() => {this.doLogin()}}><Icon icon="sign-in" /> Login</Button>
            <Button appearance="link" size="lg"><Icon icon="question" /> Forgot password</Button>
          </FormGroup>
        </Form>
      </FlexboxGrid.Item>
    </FlexboxGrid>;
  }
}

export default withApollo(Login);