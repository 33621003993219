import React from 'react';

import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Checkbox,
  Container,
  ControlLabel,
  FlexboxGrid,
  Form,
  FormGroup,
  Icon,
  Input,
  InputGroup,
  Loader,
  Message,
  Modal,
  SelectPicker,
  Steps,
  Table,
  Toggle,
  Tooltip,
  Whisper,
  HelpBlock,
} from 'rsuite';

import { compose, graphql, withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import moment from 'moment-timezone';

import Breadcrumbs from '../../Components/Breadcrumbs';
import queries from './queries';
import References from '../../Helpers/References';
import UnitOta from '../../Components/UnitOta';

class UserActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmDelete: false,
      working: false,
    };

    this.handleDeleteUnit = this.handleDeleteUnit.bind(this);
    this.doDeleteUnit = this.doDeleteUnit.bind(this);
  }

  handleDeleteUnit() {
    this.setState({
      showConfirmDelete: true,
    });
  }

  doDeleteUnit() {
    this.setState({
      working: true
    });

    const mutation = gql`
    mutation um_deleteUnit ($unit_id: Int!) {
      um_deleteUnit (unit_id: $unit_id)
    }`;
    const variables = {
      unit_id: this.props.rowData.unit_id,
    }

    this.props.client.mutate({mutation, variables})
    .then((res) => {
      console.log('delete user success:', res);
      this.props.onDelete();
      this.setState({
        working: false
      });
    })
    .catch((err) => {
      console.log('delete user error:', err);
    })
  }

  render() {
    return (
      !this.state.working
      ? !this.state.showConfirmDelete
        ? <span>
          <a onClick={this.props.handleEdit.bind(this)}><Icon icon="pencil" /></a>&nbsp;
          <a onClick={this.handleDeleteUnit}><Icon icon="trash" /></a>
        </span>
        : <ButtonGroup>
            <Button size="xs" color="red" onClick={this.doDeleteUnit}><Icon icon="trash" /> Confirm</Button>
            <Button size="xs" onClick={()=>{this.setState({showConfirmDelete: false})}}><Icon icon="ban" /> Cancel</Button>
          </ButtonGroup>
      : <Loader title="Working..." />
    );
  }
}

class Inventory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showUnitDetailModal: false,
      showMoveSelectedModal: false,
      showDeleteSelectedModal: false,
      deletingSelectedUnits: false,
      deleteSelectedUnitsConfirmButtonText: <React.Fragment><Icon icon="trash" /> Confirm</React.Fragment>,
      moveToCompId: null,
      moveTerm: 12,
      moveInvoiceNumber: '',
      selectAllUnits: false,
      indeterminate: false,
      selectedUnits: [],
      // Bulk Import
      showBulkImportModal: false,
      bulkImportStep: 0,
      bulkImportCompId: null,
      bulkImportInvoiceNumber: '',
      bulkImportUnitTypeId: 0,
      bulkImportWorking: false,
      bulkImportNote: 'Working...',
      // Bulk Move
      showBulkMoveModal: false,
      bulkMoveStep: 0,
      bulkMoveImeis: '',
      bulkMoveUnits: null,
      bulkMoveToCompId: null,
      bulkMoveInvoiceNumber: '',
      bulkMoveTerm: 12,
      editUnit: null,
      newUnit: {
        rgeo: false,
      },
      imeis: '',
      dupeUnits: null,
      // Mass Ota
      showMassOtaModal: false,
    };

    this.handleUnitDetailFieldChange = this.handleUnitDetailFieldChange.bind(this);
    this.saveUnitDetail = this.saveUnitDetail.bind(this);
    this.handleSortColumn = this.handleSortColumn.bind(this);
    this.getSortedData = this.getSortedData.bind(this);    
    this.startBulkImport = this.startBulkImport.bind(this);
    this.doBulkImport = this.doBulkImport.bind(this);
    this.doBulkMove = this.doBulkMove.bind(this);
    this.renderMoveSelectedModal = this.renderMoveSelectedModal.bind(this);
    this.renderDeleteSelectedModal = this.renderDeleteSelectedModal.bind(this);
    this.renderBulkImportModal = this.renderBulkImportModal.bind(this);
    this.renderBulkImportStepContent = this.renderBulkImportStepContent.bind(this);
    this.renderBulkImportStepFooter = this.renderBulkImportStepFooter.bind(this);
    this.renderBulkMoveModal = this.renderBulkMoveModal.bind(this);
    this.renderBulkMoveStepContent = this.renderBulkMoveStepContent.bind(this);
    this.renderBulkMoveStepFooter = this.renderBulkMoveStepFooter.bind(this);
    this.renderMassOtaModal = this.renderMassOtaModal.bind(this);
    this.validateBulkMoveData = this.validateBulkMoveData.bind(this);
    this.handleCheckAllUnits = this.handleCheckAllUnits.bind(this);
    this.handleCheckUnit = this.handleCheckUnit.bind(this);
    this.doMoveSelectedUnits = this.doMoveSelectedUnits.bind(this);
    this.doDeleteSelectedUnits = this.doDeleteSelectedUnits.bind(this);
    this.mapAndShowUnitDetail = this.mapAndShowUnitDetail.bind(this);
  }

  componentDidMount() {
    if (
      this.props.match.params.unit_id &&
      this.props.inventory.um_units &&
      this.props.inventory.um_units.length > 0
    ) {
      this.mapAndShowUnitDetail(this.props);
    }
  }

  componentWillReceiveProps(props) {
    console.log('Incoming Props:', props);

    if (
      props.match.params.unit_id &&
      props.inventory.um_units &&
      props.inventory.um_units.length > 0
    ) {
      this.mapAndShowUnitDetail(props);
    }
  }

  mapAndShowUnitDetail(props) {
    props.inventory.um_units.forEach(unit => {
      if (props.match.params.unit_id == unit.unit_id) {
        this.setState({
          showUnitDetailModal: true,
          unitDetailModelTitle: <React.Fragment><Icon icon="location-arrow" /> {unit.label}</React.Fragment>,
          unitDetailActionButtonText: <React.Fragment><Icon icon="save" /> Save</React.Fragment>,
          editUnit: unit,
        });
      }
    });
  }

  handleUnitDetailFieldChange(field, value) {
    if (this.state.editUnit != null) {
      let editUnit = Object.assign({}, this.state.editUnit);
      editUnit[field] = value;
      this.setState({editUnit});
    } else {
      let newUnit = Object.assign({}, this.state.newUnit);
      newUnit[field] = value;
      this.setState({newUnit});
    }
  }

  checkForDupeImei(imei) {
    // Check for dupes if creating a new unit
    const query = gql`query um_getDupeImeis ($imeis: [String!]) {
      um_getDupeImeis (imeis: $imeis) {
        unit_id
        compid
        account {
          title
        }
        label
        imei
        serial
        simnumber
      }
    }`;
    const variables = {
      imeis: [imei]
    };

    console.log('VARS:', variables);

    this.setState({
      dupeUnits: null,
    });

    this.props.client.query({query, variables})
    .then((res) => {
      console.log('Dupe IMEI:', res);
      this.setState({
        dupeUnits: res.data.um_getDupeImeis
      });
    })
    .catch((err) => {
      console.error('Error with creating new unit:', err);
    });
  }

  saveUnitDetail() {
    let mutation = gql`mutation um_addUnit(
      $label: String!,
      $unittype_id: Int!,
      $rgeo: Boolean!,
      $imei: String!,
      $serial: String!,
      $simnumber: String!
    ) { um_addUnit(
        label: $label,
        unittype_id: $unittype_id,
        rgeo: $rgeo,
        imei: $imei,
        serial: $serial,
        simnumber: $simnumber
      )
    }`;
    let variables = this.state.newUnit;

    if (this.state.editUnit != null) {
      mutation = gql`mutation um_updateUnit(
        $unit_id: Int!,
        $label: String!,
        $unittype_id: Int!,
        $rgeo: Boolean!,
        $imei: String!,
        $serial: String!,
        $simnumber: String,
        $simphone: String
      ) {
        um_updateUnit(
          unit_id: $unit_id,
          label: $label,
          unittype_id: $unittype_id,
          rgeo: $rgeo,
          imei: $imei,
          serial: $serial,
          simnumber: $simnumber,
          simphone: $simphone
        )
      }`;
      variables = this.state.editUnit;
    }

    this.props.client.mutate({mutation, variables})
    .then((res) => {
      console.log('Successfully saved unit details:', res);
      this.setState({
        showUnitDetailModal: false,
        dupeUnits: null,
      });
      this.props.inventory.refetch();
    })
    .catch((err) => {
      console.error('Error saving unit detail:', err);
    });
  }

  handleSortColumn(sortColumn, sortType) {
    this.setState({sortColumn, sortType});
  }

  getSortedData() {
    const { sortColumn, sortType } = this.state;
    if (sortColumn && sortType) {
      return this.props.inventory.um_units.sort((a, b) => {
        let x = a[sortColumn];
        let y = b[sortColumn];
        if (typeof x === 'string') {
          x = x.charCodeAt();
        }
        if (typeof y === 'string') {
          y = y.charCodeAt();
        }
        if (sortType === 'asc') {
          return x - y;
        } else {
          return y - x;
        }
      });
    }
    return this.props.inventory.um_units;
  }

  startBulkImport() {
    console.log('Start Bulk Import');
    const query = gql`query um_getDupeImeis ($imeis: [String!]) {
      um_getDupeImeis (imeis: $imeis) {
        unit_id
        compid
        account {
          title
        }
        label
        imei
        serial
        simnumber
      }
    }`;
    const variables = {
      imeis: this.state.imeis.trim().replace(/(^,)|(,$)/g, "").split(/[\n,]+/).map(imei=>imei.trim())
    };

    console.log('VARS:', variables);

    this.setState({
      bulkImportStep: 1,
      dupeUnits: null,
    });

    this.props.client.query({query, variables})
    .then((res) => {
      console.log('Bulk Import RES:', res);
      this.setState({
        dupeUnits: res.data.um_getDupeImeis
      });
    })
    .catch((err) => {
      console.error('Error with bulk import:', err);
    })
  }

  doBulkImport() {
    let mutation = gql`mutation um_addUnitsFromImeis (
      $imeis: [String]!,
      $unittype_id: Int!,
    ) {
      um_addUnitsFromImeis (
        imeis: $imeis,
        unittype_id: $unittype_id,
      )
    }`;
    let variables = {
      imeis: this.state.imeis.trim().replace(/(^,)|(,$)/g, "").split(/[\n,]+/).map(imei=>imei.trim()),
      unittype_id: this.state.bulkImportUnitTypeId,
    };

    if (this.state.bulkImportCompId) {
      mutation = gql`mutation um_addUnitsFromImeis (
        $imeis: [String]!,
        $unittype_id: Int!,
        $compid: Int!,
        $invoice_number: String!
      ) {
        um_addUnitsFromImeis (
          imeis: $imeis,
          unittype_id: $unittype_id,
          compid: $compid,
          invoice_number: $invoice_number
        )
      }`;
      variables.compid = this.state.bulkImportCompId;
      variables.invoice_number = this.state.bulkImportInvoiceNumber;
    }

    console.log('VARS:', variables);

    this.setState({
      bulkImportWorking: true,
      bulkImportNote: 'Importing...',
    });

    this.props.client.mutate({mutation, variables})
    .then((res) => {
      console.log('Bulk import done:', res);
      if (res) {
        this.setState({
          bulkImportWorking: false,
          bulkImportStep: 2,
          imeis: '',
        });
        this.props.inventory.refetch();
      }
    })
    .catch((err) => {
      console.error('Bulk import error:', err);
    });
  }

  doBulkMove() {
    const mutation = gql`mutation um_moveUnits (
      $unit_ids: [Int!],
      $to_compid: Int!,
      $term: Int!,
      $invoice_number: String!
    ) {
      um_moveUnits (
        unit_ids: $unit_ids,
        to_compid: $to_compid,
        term: $term,
        invoice_number: $invoice_number
      )
    }`;
    const variables = {
      unit_ids: this.state.bulkMoveUnits.map(unit => unit.unit_id),
      to_compid: this.state.bulkMoveToCompId,
      term: this.state.bulkMoveTerm,
      invoice_number: this.state.bulkMoveInvoiceNumber,
    };

    console.log('BULK MOVE VARS:', variables);

    this.props.client.mutate({mutation, variables})
    .then((res) => {
      console.log('BULK MOVE SUCCESS:', res);
      this.setState({
        showBulkMoveModal: false,
        bulkMoveImeis: '',
        bulkMoveStep: 0,
        bulkMoveUnits: null,
        bulkMoveInvoiceNumber: '',
      });

      this.props.inventory.refetch();
    })
    .catch((err) => {
      console.error('BULK MOVE ERROR:', err);
    });
  }

  renderUnitDetailsModal() {
    return <Modal
      show={this.state.showUnitDetailModal}
      keyboard
    >
      <Modal.Header closeButton={false}>
        <Modal.Title>{this.state.unitDetailModelTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form fluid>
          <FormGroup>
            <ControlLabel>Label</ControlLabel>
            <Input
              name="label"
              placeholder="label"
              value={this.state.editUnit != null ? this.state.editUnit.label : this.state.newUnit.label}
              onChange={e => {this.handleUnitDetailFieldChange('label', e)}}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Unit Type</ControlLabel>
            <SelectPicker
              cleanable={false}
              data={
                this.props.unittypes && this.props.unittypes.um_unitTypes
                ? this.props.unittypes.um_unitTypes.map((ut) => ({label: ut.name, value: ut.unittype_id}))
                : []
              }
              value={this.state.editUnit != null ? this.state.editUnit.unittype_id : this.state.newUnit.unittype_id}
              onChange={e => {this.handleUnitDetailFieldChange('unittype_id', e)}}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>RGeo</ControlLabel>
            <Toggle
              checked={this.state.editUnit != null ? this.state.editUnit.rgeo : this.state.newUnit.rgeo}
              onChange={e => {this.handleUnitDetailFieldChange('rgeo', e)}}
              checkedChildren="On"
              unCheckedChildren="Off"
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>IMEI</ControlLabel>
            <InputGroup>
              <Input
                name="imei"
                placeholder="imei"
                value={this.state.editUnit != null ? this.state.editUnit.imei : this.state.newUnit.imei}
                onChange={e => {
                  this.handleUnitDetailFieldChange('imei', e)
                  this.setState({
                    dupeUnits: null
                  });
                }}
                onBlur={() => {
                  if (!this.state.editUnit) {
                    this.checkForDupeImei(this.state.newUnit.imei);
                  }
                }}
              />
              {
                this.state.dupeUnits != null
                ? this.state.dupeUnits.length == 0
                  ? <InputGroup.Addon><Icon icon="check" style={{color: 'green'}} /></InputGroup.Addon>
                  : <InputGroup.Addon><Icon icon="close" style={{color: 'red'}} /></InputGroup.Addon>
                : ''
              }
            </InputGroup>
            {
              this.state.dupeUnits != null
              ? this.state.dupeUnits.length > 0
                ? <HelpBlock style={{color: 'red'}}>Duplicate IMEI</HelpBlock>
                : <HelpBlock style={{color: 'green'}}>Unique IMEI</HelpBlock>
              : ''
            }
          </FormGroup>
          <FormGroup>
            <ControlLabel>Serial</ControlLabel>
            <Input
              name="serial"
              placeholder="serial"
              value={this.state.editUnit != null ? this.state.editUnit.serial : this.state.newUnit.serial}
              onChange={e => {this.handleUnitDetailFieldChange('serial', e)}}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Sim Number</ControlLabel>
            <Input
              name="simnumber"
              placeholder="simnumber"
              value={this.state.editUnit != null ? this.state.editUnit.simnumber : this.state.newUnit.simnumber}
              onChange={e => {this.handleUnitDetailFieldChange('simnumber', e)}}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Sim Phone</ControlLabel>
            <Input
              name="simphone"
              placeholder="simphone"
              value={this.state.editUnit != null ? this.state.editUnit.simphone : this.state.newUnit.simphone}
              onChange={e => {this.handleUnitDetailFieldChange('simphone', e)}}
            />
          </FormGroup>
          {
            this.state.editUnit != null
            ? <UnitOta units={[this.state.editUnit]} />
            : ''
          }          
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={this.saveUnitDetail}
          appearance="primary"
          color="green"
          disabled={this.state.editUnit == null ? this.state.dupeUnits == null || this.state.dupeUnits.length > 0 : false}          
        >{this.state.unitDetailActionButtonText}</Button>
        <Button onClick={() => {this.setState({showUnitDetailModal: false})}} appearance="subtle"><Icon icon="ban" /> Cancel</Button>
      </Modal.Footer>
    </Modal>;
  }

  renderBulkImportStepContent() {
    switch (this.state.bulkImportStep) {
      case 0: // Import
        return <React.Fragment>
          <FormGroup>
            <ControlLabel>Customer</ControlLabel>
            <SelectPicker
              cleanable={false}
              data={
                this.props.accounts && this.props.accounts.um_accounts
                ? [
                  this.props.account && this.props.account.um_account
                    ? {label: <React.Fragment>{this.props.account.um_account.title} <Whisper trigger="hover" speaker={<Tooltip>Main Account</Tooltip>}><Icon icon="home" /></Whisper></React.Fragment>, value: this.props.account.um_account.compid}
                    : {label: 'Loading...', value: 0},
                  ...this.props.accounts.um_accounts.map((ut) => ({label: ut.title, value: ut.compid})),
                ]
                : []
              }
              value={
                this.state.bulkImportCompId != null
                ? this.state.bulkImportCompId
                : this.props.account && this.props.account.um_account && this.props.account.um_account.compid
                  ? this.props.account.um_account.compid
                  : 0
              }
              defaultValue={this.props.account && this.props.account.um_account && this.props.account.um_account.compid
                ? this.props.account.um_account.compid
                : 0
              }
              onChange={e => {this.setState({bulkImportCompId: e})}}
            />
          </FormGroup>
          {
            this.props.account &&
            this.props.account.um_account &&
            this.props.account.um_account.compid &&
            this.state.bulkImportCompId != null &&
            this.state.bulkImportCompId != this.props.account.um_account.compid
            ? <FormGroup>
              <ControlLabel>Invoice #</ControlLabel>
              <Input
                placeholder="Invoice #"
                value={this.state.bulkImportInvoiceNumber}
                onChange={bulkImportInvoiceNumber => this.setState({bulkImportInvoiceNumber})}
              />
            </FormGroup>
            : ''
          }
          <FormGroup>
            <ControlLabel>Unit Type</ControlLabel>
            <SelectPicker
              cleanable={false}
              data={
                this.props.unittypes && this.props.unittypes.um_unitTypes
                ? this.props.unittypes.um_unitTypes.map((ut) => ({label: ut.name, value: ut.unittype_id}))
                : []
              }
              value={this.state.bulkImportUnitTypeId}
              onChange={e => {this.setState({bulkImportUnitTypeId: e})}}
            />
          </FormGroup>
          <FormGroup>
            <Input
              name="imeis"
              placeholder="imeis"
              componentClass="textarea"
              rows={20}
              value={this.state.imeis}
              onChange={imeis => {this.setState({imeis})}}
            />
          </FormGroup>
        </React.Fragment>
      case 1: // Confirmation
        return <React.Fragment>
          {
            this.state.bulkImportWorking
            ? <Loader content={this.state.bulkImportNote} />
            : this.state.dupeUnits != null
              ? this.state.dupeUnits.length > 0
                ? <React.Fragment>
                    <Message showIcon type="warning" description="The following units were found containing duplicate IMEIs." />
                    <Table data={this.state.dupeUnits}>
                      <Table.Column width={50}>
                        <Table.HeaderCell>ID</Table.HeaderCell>
                        <Table.Cell dataKey="unit_id" />
                      </Table.Column>
                      <Table.Column>
                        <Table.HeaderCell>Customer</Table.HeaderCell>
                        <Table.Cell dataKey="account.name" />
                      </Table.Column>
                      <Table.Column>
                        <Table.HeaderCell>Label</Table.HeaderCell>
                        <Table.Cell dataKey="label" />
                      </Table.Column>
                      <Table.Column>
                        <Table.HeaderCell>IMEI</Table.HeaderCell>
                        <Table.Cell dataKey="imei" />
                      </Table.Column>
                      <Table.Column>
                        <Table.HeaderCell>Sim Number</Table.HeaderCell>
                        <Table.Cell dataKey="simnumber" />
                      </Table.Column>
                    </Table>
                  </React.Fragment>                
                : <Message showIcon type="success" description="No duplicates found. Please click Confirm to complete the import!" />
              : <Loader content="Checking for duplicates..." />
          }
        </React.Fragment>
      case 2: // Done
          return <React.Fragment>
            <Message showIcon type="success" description="Congratulations! You have successfully imported new units." />
          </React.Fragment>
    }
  }

  renderBulkImportStepFooter() {
    switch (this.state.bulkImportStep) {
      case 0:
        return <React.Fragment>
          <Button onClick={this.startBulkImport} appearance="primary" color="green" disabled={this.state.imeis.length < 1 || this.state.bulkImportUnitTypeId == 0}><Icon icon="import" /> Import</Button>
          <Button onClick={() => {this.setState({showBulkImportModal: false, imeis: '', bulkImportUnitTypeId: 0})}} appearance="subtle"><Icon icon="ban" /> Cancel</Button>
        </React.Fragment>
      case 1:
        return <React.Fragment>
          <Button onClick={() => {this.setState({bulkImportStep: 0})}} appearance="subtle"><Icon icon="chevron-left" /> Back</Button>
          <Button onClick={() => {this.doBulkImport()}} disabled={this.state.dupeUnits == null || this.state.dupeUnits.length > 0} appearance="primary" color="green"><Icon icon="thumbs-up" /> Confirm</Button>
        </React.Fragment>
      case 2:
        return <React.Fragment>
          <Button onClick={() => {this.setState({bulkImportStep: 0, showBulkImportModal: false})}} appearance="primary" color="green"><Icon icon="check" /> Done</Button>
        </React.Fragment>
    }
  }

  renderBulkImportModal() {
    return <Modal
      show={this.state.showBulkImportModal}
      keyboard
    >
      <Modal.Header closeButton={false}>
        <Modal.Title><Icon icon="import" /> Bulk Import</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form fluid>
          <FormGroup>
            <Steps current={this.state.bulkImportStep}>
              <Steps.Item title="Import" />
              <Steps.Item title="Confirmation" />
              <Steps.Item title="Done" />
            </Steps>
          </FormGroup>
          {this.renderBulkImportStepContent()}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {this.renderBulkImportStepFooter()}        
      </Modal.Footer>
    </Modal>;
  }

  startBulkMove() {
    this.setState({
      bulkMoveStep: 1
    });

    // Match units from imei
    const query = gql`query um_getUnitsFromImeis($imeis: [String!]) {
      um_getUnitsFromImeis(imeis: $imeis) {
        unit_id
        compid
        account {
          title
        }
        label
        imei
        serial
        simnumber
      }
    }`;
    const variables = {
      imeis: this.state.bulkMoveImeis.trim().replace(/(^,)|(,$)/g, "").split(/[\n,]+/).map(imei=>imei.trim())
    };

    console.log('BULK MOVE VARS:', variables);

    this.props.client.query({query, variables})
    .then((res) => {
      console.log('BULK MOVE RES:', res);
      this.setState({
        bulkMoveUnits: res.data.um_getUnitsFromImeis
      });
    })
    .catch((err) => {
      console.error('BULK MOVE ERROR:', err);
    });
  }

  validateBulkMoveData() {
    if (!this.state.bulkMoveToCompId) {
      return false;
    }
    if (
      this.state.bulkMoveToCompId != this.props.account.um_account.compid &&
      this.state.bulkMoveInvoiceNumber.length == 0
    ) {
      return false;
    }

    return true;
  }

  validateBulkMoveConfirmData() {
    if (!this.state.bulkMoveUnits) {
      return false;
    }
    if (this.state.bulkMoveUnits.length != this.state.bulkMoveImeis.trim().replace(/(^,)|(,$)/g, "").split(/[\n,]+/).map(imei=>imei.trim()).length) {
      return false;
    }
    if (!this.state.bulkMoveToCompId) {
      return false;
    }
    if (
      this.state.bulkMoveToCompId != this.props.account.um_account.compid &&
      this.state.bulkMoveInvoiceNumber.length == 0
    ) {
      return false;
    }

    return true;
  }

  renderBulkMoveStepContent() {
    switch (this.state.bulkMoveStep) {
      case 0: return <React.Fragment>
        <FormGroup>
          <ControlLabel>IMEIs</ControlLabel>
          <Input componentClass="textarea" rows={5} value={this.state.bulkMoveImeis} onChange={e => {this.setState({bulkMoveImeis: e})}} />
        </FormGroup>
        <FormGroup>
          <ControlLabel>To Company</ControlLabel>
          <SelectPicker
            cleanable={false}
            data={
              this.props.accounts && this.props.accounts.um_accounts
              ? [
                this.props.account && this.props.account.um_account
                  ? {label: <React.Fragment>{this.props.account.um_account.title} <Whisper trigger="hover" speaker={<Tooltip>Main Account</Tooltip>}><Icon icon="home" /></Whisper></React.Fragment>, value: this.props.account.um_account.compid}
                  : {label: 'Loading...', value: 0},
                ...this.props.accounts.um_accounts.map((ut) => ({label: ut.title, value: ut.compid})),
              ]
              : []
            }
            value={this.state.bulkMoveToCompId}
            onChange={e => {this.setState({bulkMoveToCompId: e})}}
          />
        </FormGroup>
        {
          this.state.bulkMoveToCompId &&
          this.props.account &&
          this.props.account.um_account &&
          this.props.account.um_account.compid != this.state.bulkMoveToCompId
          ? <FormGroup>
            <ControlLabel>Invoice #</ControlLabel>
            <Input
              placeholder="Invoice #"
              value={this.state.bulkMoveInvoiceNumber}
              onChange={bulkMoveInvoiceNumber => this.setState({bulkMoveInvoiceNumber})}
            />
          </FormGroup>
          : ''
        }
        <FormGroup>
          <ControlLabel>Term</ControlLabel>
          <SelectPicker
            cleanable={false}
            searchable={false}
            data={References.terms}
            value={this.state.bulkMoveTerm}
            onChange={e => {this.setState({bulkMoveTerm: e})}}
          />
        </FormGroup>
      </React.Fragment>
      case 1: return <React.Fragment>
        {
          this.state.bulkMoveUnits
          ? this.state.bulkMoveUnits.length > 0 && this.state.bulkMoveUnits.length == this.state.bulkMoveImeis.trim().replace(/(^,)|(,$)/g, "").split(/[\n,]+/).map(imei=>imei.trim()).length
            ? <React.Fragment>
              <Message showIcon type="success" description={'Units listed below will be moved to company ('+this.props.accounts.um_accounts.reduce((accum, account) => (account.compid == this.state.bulkMoveToCompId ? account.title : accum), '')+')'} />
              <Table data={this.state.bulkMoveUnits}>
                <Table.Column>
                  <Table.HeaderCell>ID</Table.HeaderCell>
                  <Table.Cell dataKey="unit_id" />
                </Table.Column>
                <Table.Column>
                  <Table.HeaderCell>Label</Table.HeaderCell>
                  <Table.Cell dataKey="label" />
                </Table.Column>
                <Table.Column>
                  <Table.HeaderCell>IMEI</Table.HeaderCell>
                  <Table.Cell dataKey="imei" />
                </Table.Column>
                <Table.Column>
                  <Table.HeaderCell>Sim</Table.HeaderCell>
                  <Table.Cell dataKey="simnumber" />
                </Table.Column>
                <Table.Column>
                  <Table.HeaderCell>Company</Table.HeaderCell>
                  <Table.Cell dataKey="account.title" />
                </Table.Column>
              </Table>
            </React.Fragment>
            : <Message showIcon type="error" description="Could not locate 1 or more units!" />
          : <Loader label="Finding units..." />
        }
      </React.Fragment>
    }
  }

  renderBulkMoveStepFooter() {
    switch (this.state.bulkMoveStep) {
      case 0: return <React.Fragment>
        <Button
          appearance="primary"
          color="green"
          onClick={() => {this.startBulkMove()}}
          disabled={!this.validateBulkMoveData()}
        ><Icon icon="exchange" /> Move</Button>
        <Button appearance="subtle" onClick={() => {this.setState({showBulkMoveModal: false})}}><Icon icon="ban" /> Cancel</Button>
      </React.Fragment>
      case 1: return <React.Fragment>
        <Button
          appearance="subtle"
          onClick={() => {
            this.setState({
              bulkMoveStep: 0,
              bulkMoveUnits: null,
            });
          }}
        ><Icon icon="chevron-left" /> Back</Button>
        <Button
          appearance="primary"
          onClick={this.doBulkMove}
          disabled={!this.validateBulkMoveConfirmData()}
        ><Icon icon="thumbs-up" /> Confirm</Button>
      </React.Fragment>
    }
  }

  renderBulkMoveModal() {
    return <Modal show={this.state.showBulkMoveModal}>
      <Modal.Header closeButton={false}><Modal.Title><Icon icon="exchange" /> Bulk Move</Modal.Title></Modal.Header>
      <Modal.Body>
        <Form fluid>
          <FormGroup>
            <Steps current={this.state.bulkMoveStep}>
              <Steps.Item title="Units" />
              <Steps.Item title="Confirmation" />
              <Steps.Item title="Done" />
            </Steps>
          </FormGroup>
          {this.renderBulkMoveStepContent()}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {this.renderBulkMoveStepFooter()}
      </Modal.Footer>
    </Modal>
  }

  renderMoveSelectedModal() {
    return <Modal show={this.state.showMoveSelectedModal}>
      <Modal.Header closeButton={false}><Modal.Title><Icon icon="exchange" /> Move Selected</Modal.Title></Modal.Header>
      <Modal.Body>
        <Form fluid>
          <FormGroup>
            <Message showIcon description={'Moving ' + this.state.selectedUnits.length + ' units'} />
          </FormGroup>
          <FormGroup>
            <Container>
              <Table
                data={this.props.inventory && this.props.inventory.um_units
                  ? this.props.inventory.um_units.filter(unit => this.state.selectedUnits.includes(unit.unit_id))
                  : []
                }
              >
                <Table.Column>
                  <Table.HeaderCell>ID</Table.HeaderCell>
                  <Table.Cell dataKey="unit_id" />
                </Table.Column>
                <Table.Column>
                  <Table.HeaderCell>Label</Table.HeaderCell>
                  <Table.Cell dataKey="label" />
                </Table.Column>
                <Table.Column>
                  <Table.HeaderCell>type</Table.HeaderCell>
                  <Table.Cell dataKey="unittype.name" />
                </Table.Column>
                <Table.Column>
                  <Table.HeaderCell>IMEI</Table.HeaderCell>
                  <Table.Cell dataKey="imei" />
                </Table.Column>
                <Table.Column>
                  <Table.HeaderCell>Serial</Table.HeaderCell>
                  <Table.Cell dataKey="serial" />
                </Table.Column>
                <Table.Column>
                  <Table.HeaderCell>Sim</Table.HeaderCell>
                  <Table.Cell dataKey="simnumber" />
                </Table.Column>
              </Table>
            </Container>
          </FormGroup>
          <FormGroup>
            <ControlLabel><Icon icon="star" /> To Customer Account</ControlLabel>
            <SelectPicker
              placeholder="Select destination account"
              cleanable={false}
              data={
                this.props.accounts && this.props.accounts.um_accounts
                ? this.props.accounts.um_accounts.map((ut) => ({label: ut.title, value: ut.compid}))
                : []
              }
              value={this.state.moveToCompId}
              onChange={e => {this.setState({moveToCompId: e})}}
            />
          </FormGroup>
          <FormGroup>
          <ControlLabel>Invoice #</ControlLabel>
            <Input
              placeholder="Invoice #"
              value={this.state.moveInvoiceNumber}
              onChange={moveInvoiceNumber => this.setState({moveInvoiceNumber})}
            />
          </FormGroup>
          <FormGroup>
          <ControlLabel>Term</ControlLabel>
          <SelectPicker
            cleanable={false}
            searchable={false}
            data={References.terms}
            value={this.state.moveTerm}
            onChange={e => {this.setState({moveTerm: e})}}
          />
        </FormGroup>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          appearance="primary"
          onClick={this.doMoveSelectedUnits}
          color="green"
          disabled={!this.state.moveToCompId}
        ><Icon icon="thumbs-up" /> Confirm</Button>
        <Button
          appearance="subtle"
          onClick={() => {this.setState({showMoveSelectedModal: false})}}
        ><Icon icon="ban" /> Cancel</Button>
      </Modal.Footer>
    </Modal>
  }

  renderDeleteSelectedModal() {
    return <Modal show={this.state.showDeleteSelectedModal}>
      <Modal.Header closeButton={false}><Modal.Title><Icon icon="exchange" /> Delete Selected</Modal.Title></Modal.Header>
      <Modal.Body>
        <Form fluid>
          <FormGroup>
            <Message showIcon type="warning" description={'Deleting ' + this.state.selectedUnits.length + ' units. This CANNOT be undone!'} />
          </FormGroup>
          <FormGroup>
            <Table
              data={this.props.inventory && this.props.inventory.um_units
                ? this.props.inventory.um_units.filter(unit => this.state.selectedUnits.includes(unit.unit_id))
                : []
              }
            >
              <Table.Column>
                <Table.HeaderCell>ID</Table.HeaderCell>
                <Table.Cell dataKey="unit_id" />
              </Table.Column>
              <Table.Column>
                <Table.HeaderCell>Label</Table.HeaderCell>
                <Table.Cell dataKey="label" />
              </Table.Column>
              <Table.Column>
                <Table.HeaderCell>type</Table.HeaderCell>
                <Table.Cell dataKey="unittype.name" />
              </Table.Column>
              <Table.Column>
                <Table.HeaderCell>IMEI</Table.HeaderCell>
                <Table.Cell dataKey="imei" />
              </Table.Column>
              <Table.Column>
                <Table.HeaderCell>Serial</Table.HeaderCell>
                <Table.Cell dataKey="serial" />
              </Table.Column>
              <Table.Column>
                <Table.HeaderCell>Sim</Table.HeaderCell>
                <Table.Cell dataKey="simnumber" />
              </Table.Column>
            </Table>
          </FormGroup>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          appearance="primary"
          onClick={this.doDeleteSelectedUnits}
          color="red"
          disabled={this.state.deletingSelectedUnits}
        >{this.state.deleteSelectedUnitsConfirmButtonText}</Button>
        <Button
          appearance="subtle"
          onClick={() => {this.setState({showDeleteSelectedModal: false, deletingSelectedUnits: false})}}
        ><Icon icon="ban" /> Cancel</Button>
      </Modal.Footer>
    </Modal>
  }

  renderMassOtaModal() {
    return <Modal show={this.state.showMassOtaModal} keyboard onExit={() => {this.setState({showMassOtaModal: false})}}>
      <Modal.Header closeButton={false}><Modal.Title><Icon icon="send" /> Mass OTA</Modal.Title></Modal.Header>
      <Modal.Body>
        <Form>
          <FormGroup>
            <Table
              data={this.props.inventory && this.props.inventory.um_units
                ? this.props.inventory.um_units.filter(unit => this.state.selectedUnits.includes(unit.unit_id))
                : []
              }
            >
              <Table.Column>
                <Table.HeaderCell>ID</Table.HeaderCell>
                <Table.Cell dataKey="unit_id" />
              </Table.Column>
              <Table.Column>
                <Table.HeaderCell>Label</Table.HeaderCell>
                <Table.Cell dataKey="label" />
              </Table.Column>
              <Table.Column>
                <Table.HeaderCell>type</Table.HeaderCell>
                <Table.Cell dataKey="unittype.name" />
              </Table.Column>
              <Table.Column>
                <Table.HeaderCell>IMEI</Table.HeaderCell>
                <Table.Cell dataKey="imei" />
              </Table.Column>
              <Table.Column>
                <Table.HeaderCell>Serial</Table.HeaderCell>
                <Table.Cell dataKey="serial" />
              </Table.Column>
              <Table.Column>
                <Table.HeaderCell>Sim</Table.HeaderCell>
                <Table.Cell dataKey="simnumber" />
              </Table.Column>
            </Table>
          </FormGroup>
          <UnitOta
            units={this.props.inventory && this.props.inventory.um_units
              ? this.props.inventory.um_units.filter(unit => this.state.selectedUnits.includes(unit.unit_id))
              : []
            }
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => {this.setState({showMassOtaModal: false})}}><Icon icon="check" /> Done</Button>
      </Modal.Footer>
    </Modal>
  }

  doMoveSelectedUnits() {
    const mutation = gql`mutation um_moveUnits (
      $unit_ids: [Int!],
      $to_compid: Int!,
      $term: Int!,
      $invoice_number: String!
    ) {
      um_moveUnits (
        unit_ids: $unit_ids,
        to_compid: $to_compid,
        term: $term,
        invoice_number: $invoice_number
      )
    }`;
    const variables = {
      unit_ids: this.state.selectedUnits,
      to_compid: this.state.moveToCompId,
      term: this.state.moveTerm,
      invoice_number: this.state.moveInvoiceNumber,
    };

    console.log('BULK MOVE VARS:', variables);

    this.props.client.mutate({mutation, variables})
    .then((res) => {
      console.log('BULK MOVE SUCCESS:', res);
      this.setState({
        showMoveSelectedModal: false,
        selectedUnits: [],
        selectAllUnits: false,
        moveInvoiceNumber: '',
      });

      this.props.inventory.refetch();
    })
    .catch((err) => {
      console.error('BULK MOVE ERROR:', err);
    });
  }

  doDeleteSelectedUnits() {
    let promises = [];
    const mutation = gql`mutation um_deleteUnit ($unit_id: Int!) {
      um_deleteUnit (unit_id: $unit_id)
    }`;

    this.setState({
      deletingSelectedUnits: true,
      deleteSelectedUnitsConfirmButtonText: <Loader content="Deleting..." />,
    });

    this.state.selectedUnits.forEach(unit_id => {
      const variables = {unit_id};
      promises.push(
        this.props.client.mutate({mutation, variables})
      );
    });

    Promise.all(promises)
    .then((res) => {
      this.setState({
        deletingSelectedUnits: false,
        showDeleteSelectedModal: false,
        deleteSelectedUnitsConfirmButtonText: <React.Fragment><Icon icon="trash" /> Confirm</React.Fragment>,
        selectedUnits: [],
        selectAllUnits: false,
      });
      this.props.inventory.refetch();
    });
  }

  handleCheckAllUnits(value, checked) {
    const selectedUnits = checked ? this.props.inventory.um_units.map(unit => unit.unit_id) : [];
    this.setState({
      selectAllUnits: value,
      selectedUnits
    });
  }

  handleCheckUnit(value, checked) {
    const { selectedUnits } = this.state;
    const nextSelectedUnits = checked
    ? [...selectedUnits, value]
    : selectedUnits.filter(unit_id => unit_id !== value);

    console.log('nextSelectedUnits:', nextSelectedUnits);

    this.setState({
      selectedUnits: nextSelectedUnits,
      selectAllUnits: nextSelectedUnits.length > 0 && nextSelectedUnits.length == this.props.inventory.um_units.length
      ? true
      : false
    });
  }

  render() {
    return <Container>
      <Breadcrumbs
        pages={[{
          url: '/inventory',
          name: 'Inventory'
        }]}
      />
      <Container className="content">
        <Container className="content-body">
          <ButtonToolbar>
            <ButtonGroup>
              <Button
                appearance="primary"
                onClick={() => {
                  this.setState({
                    showUnitDetailModal: true,
                    unitDetailModelTitle: <React.Fragment><Icon icon="location-arrow" /> New Unit</React.Fragment>,
                    unitDetailActionButtonText: <React.Fragment><Icon icon="save" /> Add</React.Fragment>,
                    editUnit: null,
                    newUnit: {
                      rgeo: false,
                    },
                  });
                }}
              ><Icon icon="plus" /> New</Button>
              <Button
                appearance="primary"
                onClick={() => {this.setState({showBulkImportModal: true})}}
              ><Icon icon="import" /> Bulk Import</Button>
              <Button
                appearance="primary"
                onClick={() => {this.setState({showBulkMoveModal: true})}}
              ><Icon icon="exchange" /> Bulk Move</Button>
            </ButtonGroup>
          </ButtonToolbar>
          {
            this.props.inventory && this.props.inventory.um_units
            ? <Table
              autoHeight
              data={this.getSortedData()}
              sortColumn={this.state.sortColumn}
              sortType={this.state.sortType}
              onSortColumn={this.handleSortColumn}
            >
              <Table.Column fixed width={50} align="center">
                <Table.HeaderCell style={{padding: 2}}>
                  <div style={{ lineHeight: 50 }}>
                    <Checkbox
                      onChange={this.handleCheckAllUnits}
                      indeterminate={this.state.selectedUnits.length > 0 && this.state.selectedUnits.length < this.props.inventory.um_units.length}
                      checked={this.state.selectAllUnits}
                    />
                  </div>
                </Table.HeaderCell>
                <Table.Cell style={{padding: 2}}>
                  {
                    rowData => {
                      return <div style={{lineHeight: 46}}>
                        <Checkbox
                          onChange={this.handleCheckUnit}
                          checked={this.state.selectedUnits.some(unit_id => unit_id === rowData.unit_id)}
                          value={rowData.unit_id}
                        />
                      </div>
                    }
                  }
                </Table.Cell>
              </Table.Column>
              <Table.Column sortable fixed>
                <Table.HeaderCell>ID</Table.HeaderCell>
                <Table.Cell dataKey="unit_id" />
              </Table.Column>
              <Table.Column width={200} sortable fixed>
                <Table.HeaderCell>Label</Table.HeaderCell>
                <Table.Cell dataKey="label" />
              </Table.Column>
              <Table.Column width={200}>
                <Table.HeaderCell>Type</Table.HeaderCell>
                <Table.Cell dataKey="unittype.name" />
              </Table.Column>
              <Table.Column width={200} sortable>
                <Table.HeaderCell>IMEI</Table.HeaderCell>
                <Table.Cell dataKey="imei" />
              </Table.Column>
              <Table.Column width={200} sortable>
                <Table.HeaderCell>Serial</Table.HeaderCell>
                <Table.Cell dataKey="serial" />
              </Table.Column>
              <Table.Column width={200} sortable>
                <Table.HeaderCell>Sim</Table.HeaderCell>
                <Table.Cell dataKey="simnumber" />
              </Table.Column>
              <Table.Column width={200}>
                <Table.HeaderCell>Created</Table.HeaderCell>
                <Table.Cell>
                {
                  rowData => {
                    return moment(rowData.createdAt).format('YYYY/MM/DD')
                  }
                }
                </Table.Cell>
              </Table.Column>
              <Table.Column width={200}>
                <Table.HeaderCell><Icon icon="cog" /></Table.HeaderCell>
                <Table.Cell>
                  {
                    rowData => {
                      function handleEdit() {
                        this.setState({
                          showUnitDetailModal: true,
                          unitDetailModelTitle: <React.Fragment><Icon icon="location-arrow" /> {rowData.label}</React.Fragment>,
                          unitDetailActionButtonText: <React.Fragment><Icon icon="save" /> Save</React.Fragment>,
                          editUnit: rowData,
                        });
                      }

                      return <UserActions key={'UserAction_'+rowData.unit_id} rowData={rowData} handleEdit={handleEdit.bind(this)} client={this.props.client} onDelete={() => {this.props.inventory.refetch()}} />;                    
                    }
                  }
                </Table.Cell>
              </Table.Column>
            </Table>
            : <Loader content="Loading..." />
          }
          <ButtonToolbar>
            <ButtonGroup>
              <Button
                appearance="primary"
                onClick={() => {this.setState({showMoveSelectedModal: true})}}
                disabled={this.state.selectedUnits.length == 0}
              ><Icon icon="exchange" /> Move Selected</Button>
              <Button
                appearance="primary"
                onClick={() => {this.setState({showMassOtaModal: true})}}
                disabled={this.state.selectedUnits.length == 0}
              ><Icon icon="send" /> Mass OTA</Button>
              <Button
                appearance="primary"
                onClick={() => {this.setState({showDeleteSelectedModal: true})}}
                color="red"
                disabled={this.state.selectedUnits.length == 0}
              ><Icon icon="trash" /> Delete Selected</Button>
            </ButtonGroup>
          </ButtonToolbar>
        </Container>
      </Container>
      {this.renderUnitDetailsModal()}
      {this.renderBulkImportModal()}
      {this.renderBulkMoveModal()}
      {this.renderMoveSelectedModal()}
      {this.renderDeleteSelectedModal()}
      {this.renderMassOtaModal()}
    </Container>;
  }
}

export default compose(
  graphql(queries.inventory, {
    name: 'inventory',
  }),
  graphql(queries.unittypes, {
    name: 'unittypes',
  }),
  graphql(queries.accounts, {
    name: 'accounts',
  }),
  graphql(queries.account, {
    name: 'account',
  }),
)(withApollo(Inventory));