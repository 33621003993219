import gql from 'graphql-tag';

const queries = {
  account: gql`query um_account($compid: Int!) {
    um_account(compid: $compid) {
      compid
      title
      pings
      account_type
      dealer
      dealer_type
      account_tz
      email_settings
      maintenance_email
      account_permissions
      account_settings
      state_id
      eot_version
      eot_username
      eot_password
      eot_count
      creation_date
      markup
      security_code
      registered
      opt_out_code
      popups
      paypal_id
      batt_threshold
      root_compid
      defaultbucket_id
      masteruser_id
      installedUnitCount
      unitCount
      assman
    }
  }`,
  units: gql`
  query um_units ($compid: Int!) {
    um_units (compid: $compid) {
      unit_id
      compid
      conduit_id
      hid
      unittype_id
      imei
      serial
      rgeo
      term
      simnumber
      simphone
      lastmovetime
      laststoptime
      simactive
      drive_off_compid
      next_renew_date
      transferdate
      expired_confirmed
      eot_version
      eot_retry
      alert_sent_7
      alert_sent_30
      alert_sent_60
      expiration_alert_sent
      subscription_cycle
      label
      timezone
      function
      programming
      yearly_price
      reseller_compid
      reseller_markup
      dealer_compid
      dealer_markup
      kore_status
      last_activated
      about_unit
      pings_enabled
      unlimited_pings
      preload
      salesrep
      category
      vzw_vpn
      vzw_bucket
      group_id
      conduit_status
      pu_status
      simtype_id
      bucket_id
      manufacturerPO
      carrierstatus_id
      active
      ignitiontype
      starterinterrupt
      trackingmode
      trackingexpire
      lastreportedvoltage
      repotoken
      repotokenexpire
      installationdate
      createdAt
      unittype {
        unittype_id
        carrier_id
        name
        details
      }
      vehicle {
        vehicle_id
        compid
        unit_id
        label
        vehiclemake_id
        model
        year
        color
        vin
        stocknumber
        license
        address
        notes
        installed
        icon
        odometer
      }
      invoice_number
    }
  }`,
  unittypes: gql`
  query {
    um_unitTypes {
      unittype_id
      carrier_id
      name
      details
    }
  }`,
  vehiclemakes: gql`
  query {
    um_vehiclemakes {
      vehiclemake_id
      name
    }
  }`,
  users: gql`
  query um_users ($compid: Int!) {
    um_users (compid: $compid) {
      user_id
      compid
      username
      password
      fullname
      firstname
      lastname
      email
      address
      phone
      dealer_login
      user_type
      master_account
      sm
      permissions
      timezone
    }
  }`,
};

export default queries;