import gql from 'graphql-tag';

const queries = {
  inventory: gql`query {
    um_units {
      unit_id
      compid
      conduit_id
      hid
      unittype_id
      imei
      serial
      rgeo
      simnumber
      simphone
      lastmovetime
      laststoptime
      simactive
      drive_off_compid
      next_renew_date
      expired_confirmed
      eot_version
      eot_retry
      alert_sent_7
      alert_sent_30
      alert_sent_60
      expiration_alert_sent
      subscription_cycle
      label
      timezone
      function
      programming
      yearly_price
      reseller_compid
      reseller_markup
      dealer_compid
      dealer_markup
      kore_status
      last_activated
      about_unit
      pings_enabled
      unlimited_pings
      preload
      salesrep
      category
      vzw_vpn
      vzw_bucket
      group_id
      conduit_status
      pu_status
      simtype_id
      bucket_id
      manufacturerPO
      carrierstatus_id
      active
      ignitiontype
      starterinterrupt
      trackingmode
      trackingexpire
      lastreportedvoltage
      repotoken
      repotokenexpire
      unittype {
        unittype_id
        carrier_id
        name
        details
      }
      createdAt
    }
  }`,
  unittypes: gql`query {
    um_unitTypes {
      unittype_id
      carrier_id
      name
      details
    }
  }`,
  accounts: gql`query {
    um_accounts {
      compid
      title
    }
  }`,
  account: gql`query {
    um_account {
      compid
      title
    }
  }`,
};

export default queries;