import React from 'react';
import {
  Container,
  FlexboxGrid,
} from 'rsuite';

import References from '../Helpers/References';

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentWillReceiveProps(props) {}

  render() {
    return <FlexboxGrid className="footer" justify="space-between">
      <FlexboxGrid.Item colspan={12}>GPS Leaders LLC</FlexboxGrid.Item>
      <FlexboxGrid.Item colSpan={12}>Build {References.build}</FlexboxGrid.Item>
    </FlexboxGrid>;
  }
}

export default Footer;